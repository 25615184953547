import React from "react";
import {Route, Switch} from "react-router-dom";

import StockGeneral from "./stock/StockGeneral";
import Approvisionnements from "./stock/Approvisionnements";
import StockAgence from "./stock/StockAgence";
import Repartitions from "./stock/Repartitions";
import Facture from "./facture/index";
import Commandes from "./commande/Commandes"
import CmdPointServeur from "./commande/CmdPointServeur"
import CmdPointsDetails from "./commande/CmdPointsDetails"


import asyncComponent from "../../util/asyncComponent";

const Gestion = ({match}) => (
  <Switch>
    <Route path={`${match.url}/stock/stockGeneral`} component={StockGeneral}/>
    <Route path={`${match.url}/stock/stockAgence`} component={StockAgence}/>
    <Route path={`${match.url}/stock/approvisionnement`} component={Approvisionnements}/>
    <Route path={`${match.url}/stock/repartitions`} component={Repartitions}/>
    
    
    <Route path={`${match.url}/commandes/general`} component={Commandes}/>
    <Route path={`${match.url}/commandes/details`} component={CmdPointsDetails}/>
    <Route path={`${match.url}/commandes/serveur`} component={CmdPointServeur}/>


    <Route path={`${match.url}/facture`} component={Facture}/>

    

  
    
    
  </Switch>
);

export default Gestion;
