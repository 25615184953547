import React from "react";
import { Card, Divider, Icon, Table, Input, Button, Modal, Select, message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Media from 'react-media';

import { use_logiciel } from '../../../env'

import { services } from '../../../services/services'
import { authenticationService } from "../../../services/authentication-service";


const InputGroup = Input.Group;

const currentUser = authenticationService.currentUserValue


class Agents extends React.Component {
  state = {
    isModalVisible: false,
    isAddModalVisible: false,
    isUpdateModalVisible: false,
    loading: false,
    isOpenDelete: false,
    agents: [],
    agences: [],
    gender: 'M',
    prenomAgent: '',
    codAgence: '',
    nomAgent: '',
    profession: '',
    address: '',
    contactAgent: '',
    role: 'AGENT_ROLE',
    username: '',
    password: '',
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    services.getAgents()
      .then(agents => {
        //console.log('agents', agents)
        this.setState({ agents })
      })

    services.getAllAgences()
      .then(agences => {
        // console.log('agences', agences)
        this.setState({ agences })
      })
  }

  handleAddOpen = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      codAgence: '',
      prenomAgent: '',
      nomAgent: '',
      profession: '',
      address: '',
      contactAgent: '',
      gender: 'M',
      username: '',
      password: '',
      role: 'AGENT_ROLE',
    });
  };

  handleCancelAdd = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      codAgence: '',
      prenomAgent: '',
      nomAgent: '',
      profession: '',
      address: '',
      contactAgent: '',
      gender: 'M',
      username: '',
      password: '',
      role: 'AGENT_ROLE',


    })
  }

  handleAddAgent = () => {
    const {
      codAgence,
      prenomAgent,
      nomAgent,
      profession,
      address,
      contactAgent,
      gender,
      role,
      username,
      password,
    } = this.state


    if (prenomAgent === '') {
      message.warning('Veuillez renseigner le champ Prénom', 5)
      return;
    }
    if (nomAgent === '') {
      message.warning('Veuillez renseigner le champ Nom', 5)
      return;
    }

    /*if (codAgence === '') {
      message.warning('Veuillez sélectionner une Agence', 5)
      return;
    }*/

    if (username === '') {
      message.warning('Veuillez renseigné le champ Utilisateur', 5)
      return;
    }

    if (password === '') {
      message.warning('Veuillez renseigné le champ Mot de passe', 5)
      return;
    }

    let groupe;
    if (role === "ADMIN_ROLE") {
      groupe = "SUPERVISEUR"
    } else {
      groupe = "VENTE"
    }

    this.setState({ loading: true })

    let req = {
      codAgence,
      prenomAgent,
      nomAgent,
      profession,
      address,
      contactAgent,
      gender,
      role,
      username: username.toUpperCase(),
      password,
      groupe

    }
    services.addAgent(req)
      .then(result => {
        // console.log(result)
        this.setState({
          codAgence: '',
          prenomAgent: '',
          nomAgent: '',
          profession: '',
          address: '',
          contactAgent: '',
          gender: 'M',
          role: 'AGENT_ROLE',

          username: '',
          password: '',
          loading: false,
          isAddModalVisible: !this.state.isAddModalVisible,

        })
        message.success('Agent ajouter avec succès', 10)

        services.getAgents()
          .then(agents => {
            this.setState({ agents })
          })

      })

      .catch((err) => {
        message.error(err, 10)
      });
  }

  handleUpdateAgent = () => {
    const {
      idAgent,
      codAgence,
      prenomAgent,
      nomAgent,
      profession,
      address,
      contactAgent,
      gender,
      username,
      password,
      role
    } = this.state


    if (prenomAgent === '') {
      message.warning('Veuillez renseigner le champ Prénom', 5)
      return;
    }
    if (nomAgent === '') {
      message.warning('Veuillez renseigner le champ Nom', 5)
      return;
    }

    /*if (codAgence === '') {
      message.warning('Veuillez sélectionner une Agence', 5)
      return;
    }*/

    if (username === '') {
      message.warning('Veuillez renseigné le champ Utilisateur', 5)
      return;
    }

    if (password === '') {
      message.warning('Veuillez renseigné le champ Mot de passe', 5)
      return;
    }

    let groupe;
    if (role === "ADMIN_ROLE") {
      groupe = "SUPERVISEUR"
    } else {
      groupe = "VENTE"
    }

    let req = {
      codAgence,
      prenomAgent,
      nomAgent,
      profession,
      address,
      contactAgent,
      gender,
      groupe,
      username: username.toUpperCase(),
      password,
      role,

    }
    console.log('REQ Update', req)
    services.updateAgent(idAgent, req)
      .then(result => {
        //  console.log(result)
        this.setState({
          idAgent: '',
          codAgence: '',
          prenomAgent: '',
          nomAgent: '',
          profession: '',
          address: '',
          contactAgent: '',
          gender: '',
          isUpdateModalVisible: !this.state.isUpdateModalVisible,

        })
        message.success('Agent modifier avec succès', 10)

        services.getAgents()
          .then(agents => {
            this.setState({ agents })
          })

      })

      /*.catch(() => {
        message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
      });*/
      .catch((err) => {
        message.error(err, 10)
      })
  }

  handleDelete = (row) => {
    //  console.log(row)
    this.setState({
      idAgent: row.id,
      prenomAgent: row.prenomAgent,
      nomAgent: row.nomAgent,
      profession: row.profession,
      isOpenDelete: !this.state.isOpenDelete,
      statutCompte: row.statutCompte,

      user: row.user,

    })

  }

  handleDeleteAgent = () => {

    const { idAgent } = this.state

    services.deleteAgent(idAgent)
      .then(del => {
        //console.log('delete user', del)
        message.success('Agent supprimer avec succès', 10)
        services.getAgents()
          .then(agents => {
            this.setState({
              agents: agents,
              isOpenDelete: !this.state.isOpenDelete
            })
          })

      })
      .catch((err) => {
        message.error(err, 10)
      });

  }

  handleUpdateOpen = (row) => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      idAgent: row.id,
      prenomAgent: row.prenomAgent,
      nomAgent: row.nomAgent,
      profession: row.profession,
      address: row.address,
      contactAgent: row.contactAgent,
      gender: row.gender,
      username: row.username,
      role: row.role && row.role.slug,
      codAgence: row.agence && row.agence.codAgence,
      password: row.password,


    })

  }
  handleCancelUpdate = () => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      //codAgence: '',
      prenomAgent: '',
      nomAgent: '',
      profession: '',
      address: '',
      contactAgent: '',
      gender: 'M',
      idAgent: '',
      username: '',
      password: '',
      role: 'AGENT_ROLE'

    })
  }


  handleView = (row) => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      //codAgence: row.agences,
      prenomAgent: row.prenomAgent,
      nomAgent: row.nomAgent,
      profession: row.profession,
      address: row.address,
      contactAgent: row.contactAgent,
      gender: row.gender,

      username: row.username,
      role: row.role && row.role.slug,
      codAgence: row.agence && row.agence.codAgence,
      password: row.password,

    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      // codAgence: '',
      prenomAgent: '',
      nomAgent: '',
      profession: '',
      address: '',
      contactAgent: '',
      gender: 'M',
      username: '',
      password: '',
      role: 'AGENT_ROLE'
    });
  }

  handleChangeGender = (value) => {
    this.setState({ gender: value })
  }

  handleChangeRole = (value) => {
    this.setState({ role: value })
  }

  //SELECTION AGENCE LORS CREATION OU MISE A JOUR USER
  handleChangeAgence = (value) => {
    this.setState({ codAgence: value })
  }

  OpenModalAddAgence = () => {
    this.setState({ IsModalAddAgence: !this.state.IsModalAddAgence })
  }

  handleAddAgence = () => {
    const {
      //codAgence,
      codificationAgence,
      nomAgence,
      adrAgence,
      telAgence,
      nomGerant,
      telGerant,
      observation,
    } = this.state


    if (nomAgence === '') {
      message.warning('Veuillez préciser le nom de l\'Agence', 5)
      return;
    }
    /*if (codAgence === '') {
      message.warning('Veuillez sélectionner une Agence', 5)
      return;
    }
*/
    let req = {
      //codAgence,
      codificationAgence,
      nomAgence: nomAgence.toUpperCase(),
      adrAgence,
      telAgence,
      nomGerant,
      telGerant,
      observation,
      //raisonSociale: raisonSociale.toUpperCase(),

    }
    services.addAgence(req)
      .then(result => {
        // console.log(result)
        this.setState({
          //codAgence: '',
          codificationAgence: '',
          nomAgence: '',
          adrAgence: '',
          telAgence: '',
          nomGerant: '',
          telGerant: '',
          observation: '',
          IsModalAddAgence: !this.state.IsModalAddAgence,

        })
        message.success('Agences ajouter avec succès', 6)

        services.getAllAgences()
          .then(agences => {
            this.setState({ agences })
          })

      })

      .catch((err) => {
        message.error(err, 10)
      });
  }

  handleDeleteAgentOpen = () => {
    this.setState({ isOpenDelete: !this.state.isOpenDelete })
  }


  disableUser = () => {

    const { user } = this.state

    services.disableUser(user)
      .then(del => {
        //console.log('disable user', del)
        this.setState({
          isOpenDelete: !this.state.isOpenDelete
        })

        message.success('Agents Désactiver avec succès', 6)
        services.getAgents()
          .then(agents => {
            this.setState({ agents })
          })

      })
      .catch(() => {
        message.error('Une erreur est survenue lors de la Désactivation, Veuillez réessayer plus tard', 10)
      });

  }

  enableUser = () => {

    const { user } = this.state

    services.enableUser(user)
      .then(del => {
        //console.log('delete user', del)
        this.setState({
          isOpenDelete: !this.state.isOpenDelete
        })

        message.success('Agent Activer avec succès', 6)
        services.getAgents()
          .then(agents => {
            this.setState({ agents })
          })

      })
      .catch(() => {
        message.error('Une erreur est survenue lors de l\'Activation, Veuillez réessayer plus tard', 10)
      });

  }
  //FILTRE POUR TABLEAU UTILISATEUR PAR AGENCE
  handleChangeAgence2 = (value) => {
    this.setState({ codAgenceChange: value })
    services.getAgents(value)
      .then(agents => {
        //  console.log("AGENTS2", agents)
        //console.log('agents', agents)
        this.setState({ agents })
      })
  }


  render() {
    const {
      isModalVisible,
      loading,
      agences,

      codAgence,
      codAgenceChange,
      prenomAgent,
      nomAgent,
      profession,
      address,
      contactAgent,
      gender,

      agents,


      codificationAgence,
      nomAgence,
      adrAgence,
      telAgence,
      nomGerant,
      telGerant,
      observation,

      statutCompte,

      isOpenDelete,
      isAddModalVisible,
      isUpdateModalVisible,
      IsModalAddAgence,
      role,
      username,
      password,
    } = this.state


    const Option = Select.Option;

    const { SearchBar } = Search;

    const columnsAgents = [{
      dataField: 'id',
      text: ' ID'
    },
    {
      dataField: 'username',
      text: 'Utilisateur',
      style: (cell, row, rowIndex, colIndex) => {
        if (row.statutCompte === false) {
          return {
            backgroundColor: '#F08080'
          };
        }
      }
    },
    {
      dataField: 'prenomAgent',
      text: ' Prénom',
      style: (cell, row, rowIndex, colIndex) => {
        if (row.statutCompte === false) {
          return {
            backgroundColor: '#F08080'
          };
        }
      }
    },
    {
      dataField: 'nomAgent',
      text: ' Nom ',
      style: (cell, row, rowIndex, colIndex) => {
        if (row.statutCompte === false) {
          return {
            backgroundColor: '#F08080'
          };
        }
      }
    },
    {
      dataField: 'contactAgent',
      text: 'Téléphone Agent'
    },
    {
      dataField: 'agence.nomAgence',
      text: 'Agence'
    },
    {
      dataField: 'password',
      text: ' Password',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          < div
            style={{ textAlign: "center" }} className="d-flex">
            {row && row.passwordUpdated === false &&
              row.password}
            {row && row.passwordUpdated === true &&
              <p style={{ textAlign: "center" }} className="d-flex">****</p>}
          </div>
        );
      },

    },

    {
      dataField: '',
      //hidden: use_logiciel === 'NO' ? false : true,
      text: 'Action',
      headerAlign: 'center',
      sort: true,
      headerAttrs: { width: 50 },
      align: 'center',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          < div
            style={{ textAlign: "center" }} className="d-flex">
            <Button
              type="primary"
              //fullWidth
              variant="contained"
              color="primary"
              size="sm"
              //className="btn btn-success btn-sm"
              onClick={() => this.handleView(row)}
            >
              <i className="icon icon-custom-view" />
            </Button>
            <Button
              hidden={use_logiciel === 'NO' ? false : true}
              type="primary"
              //fullWidth
              variant="contained"
              color="danger"
              size="sm"
              //className="btn btn-success btn-sm"
              onClick={() => this.handleUpdateOpen(row)}
            >
              <i className="icon icon-edit" />
            </Button>
            {/*<Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Agent?" onConfirm={this.handleDeleteAgent} onCancel={this.cancelDelete} okText="Oui"
              cancelText="Non">*/}
            <Button
              hidden={use_logiciel === 'NO' ? false : true}
              type="danger"
              //fullWidth
              variant="contained"
              color="primary"
              size="sm"
              //className="btn btn-success btn-sm"
              onClick={() => this.handleDelete(row)}
            >
              <i className="icon icon-alert-new" />
            </Button>

            {/*</Popconfirm>*/}

          </div>
        );
      },

    }

    ];


    const columnsAgentscontactAgent = [

      {
        dataField: 'username',
        text: 'Utilisateur'
      },
      {
        dataField: 'prenomAgent',
        text: ' Prénom',
        style: (cell, row, rowIndex, colIndex) => {
          if (row.statutCompte === false) {
            return {
              backgroundColor: '#F08080'
            };
          }
        }
      },
      {
        dataField: 'nomAgent',
        text: ' Nom ',
        style: (cell, row, rowIndex, colIndex) => {
          if (row.statutCompte === false) {
            return {
              backgroundColor: '#F08080'
            };
          }
        }
      },


      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
                hidden={use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Fournisseur?" onConfirm={this.handleDeleteAgent} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                  hidden={use_logiciel === 'NO' ? false : true}
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="sm"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>

            </div>
          );
        },

      }

    ];

    const defaultSorted = [{
      dataField: 'prenomAgent',
      order: 'asc'
    }];



    return (
      <div>
        {(currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true || currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === false) ?

          <Card title="Liste des Agents">

            <Media query="(min-width: 600px)" render={() =>
            (
              <ToolkitProvider
                keyField="id"
                data={agents}
                columns={columnsAgents}
                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                search
                //insertRow={true}
                exportCSV

              >
                {
                  props => (
                    <div>
                      <Row >
                        <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                          {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                          <SearchBar {...props.searchProps}
                            //delay={ 1000 }
                            style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                            placeholder="Rechercher"

                          />
                        </Col>

                        <Col md={6} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'baseline', }}>
                          {(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                            <FormGroup>
                              <Select

                                value={codAgenceChange}
                                showSearch
                                //style={{width: 200}}
                                style={{ width: '140%' }}
                                placeholder="Filtrer par Agence"
                                optionFilterProp="children"
                                onChange={this.handleChangeAgence2}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                <Option value=''>TOUTES</Option>

                                {
                                  agences && agences.length ?
                                    agences.map((agence, k) => {
                                      return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                    }) : null
                                }

                              </Select>
                              <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                            </FormGroup>
                          }


                          {use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&


                            <button style={{ marginLeft: 3 }}
                              className="btn bg-primary text-light rounded"
                              onClick={this.handleAddOpen}>
                              NOUVEAU <i className="icon icon-add" />
                            </button>
                          }
                        </Col>


                      </Row>


                      <BootstrapTable
                        {...props.baseProps}
                        //bootstrap4
                        wrapperClasses="table-responsive"
                        //selectRow={ selectRow }
                        //defaultSorted={defaultSorted}
                        pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        striped
                        hover
                        condensed
                        headerWrapperClasses="foo"
                        noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                      //filter={filterFactory()}
                      />

                    </div>

                  )
                }
              </ToolkitProvider>
            )}
            />


            <Media query="(max-width: 599px)" render={() =>
            (

              <ToolkitProvider
                keyField="id"
                data={agents}
                columns={columnsAgentscontactAgent}
                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                search
                //insertRow={true}
                exportCSV

              >
                {
                  props => (
                    <div>
                      <Row >
                        <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                          <i className="fa fa-search" style={{ margin: 10 }}></i>

                          <SearchBar {...props.searchProps}
                            //delay={ 1000 }
                            style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                            placeholder="Rechercher"

                          />
                        </Col>


                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                          {(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                            <FormGroup>
                              <Select

                                value={codAgence}
                                showSearch
                                //style={{width: 200}}
                                style={{ width: '85%' }}
                                placeholder="Filtrer par Agence"
                                optionFilterProp="children"
                                onChange={this.handleChangeAgence}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                <Option value=''>TOUTES</Option>

                                {
                                  agences && agences.length ?
                                    agences.map((agence, k) => {
                                      return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                    }) : null
                                }

                              </Select>
                              <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                            </FormGroup>}

                        </Col>
                        {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                          <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                            <button style={{ marginLeft: 3 }}
                              className="btn bg-primary text-light rounded"
                              onClick={this.handleAddOpen}>
                              NOUVEAU <i className="icon icon-add" />
                            </button>
                          </Col>
                        }

                      </Row>

                      <BootstrapTable
                        {...props.baseProps}
                        //bootstrap4
                        wrapperClasses="table-responsive"
                        //selectRow={ selectRow }
                        //defaultSorted={defaultSorted}
                        pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        striped
                        hover
                        condensed
                        headerWrapperClasses="foo"
                        noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                      //filter={filterFactory()}
                      />

                    </div>

                  )
                }
              </ToolkitProvider>
            )}
            />
          </Card>
          :
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

            </Col>
          </Row>
        }


        <Modal
          width={800}
          visible={isAddModalVisible}
          title="Ajouter un Utilisateur"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelAdd}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={this.handleAddAgent}>
              Envoyer
            </Button>,
            <Button key="back" type="danger" onClick={this.handleCancelAdd}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="prenomAgent">Prénom</Label>
                  <Input type="text" name="prenomAgent" id="prenomAgent" value={prenomAgent} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="nomAgent">Nom</Label>
                  <Input type="text" name="nomAgent" value={nomAgent} id="nomAgent" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup >
                  <Label for="gender">Sexe</Label>
                  <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                    <Option value="M">Masculin</Option>
                    <Option value="F">Feminin</Option>
                  </Select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="contactAgent">Téléphone</Label>
                  <Input type="text" name="contactAgent" value={contactAgent} id="contactAgent" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

              {/*<Col md={6}>
                <FormGroup>
                  <Label for="profession">Poste</Label>
                  <Input type="text" name="profession" value={profession} onChange={this.handleChange} id="profession" placeholder="" />
                </FormGroup>
        </Col>*/}
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup >
                  <Label for="role">Role</Label>
                  <Select className="gx-mr-6 gx-mb-6" value={role} onChange={this.handleChangeRole}>
                    <Option value="ADMIN_ROLE">Administrateur</Option>
                    <Option value="AGENT_ROLE">Agent</Option>
                  </Select>
                </FormGroup>
              </Col>
        {(currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?

              <Col md={6}>
                <FormGroup>
                  <Label for="codAgence">Agences</Label>
                  <InputGroup compact>

                    <Select

                      value={codAgence}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '75%' }}
                      placeholder="Selectionner Agence"
                      optionFilterProp="children"
                      onChange={this.handleChangeAgence}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        agences && agences.length ?
                          agences.map((agence, k) => {
                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                          }) : null
                      }

                    </Select>
                    <Button onClick={this.OpenModalAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>
              : null}
              {/*<Col md={6}>
                <FormGroup>
                  <Label for="address">Adresse</Label>
                  <Input type="text" name="address" id="address" value={address} onChange={this.handleChange} placeholder="" />
                </FormGroup>
                </Col>*/}

            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="username">Utilisateur</Label>
                  <Input type="text" name="username" id="username" value={username.toUpperCase()} onChange={this.handleChange} placeholder="JEAN" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Mot de passe</Label>
                  <Input type="password" name="password" value={password} id="password" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row form>


            </Row>

          </Form>
        </Modal>

        <Modal
          width={800}
          visible={isUpdateModalVisible}
          title="Modifier l'Utilisateur"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelUpdate}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateAgent}>
              Modifier
            </Button>,
            <Button key="back" type="danger" onClick={this.handleCancelUpdate}>
              Annuler
            </Button>,

          ]}
        >
          <Form>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="prenomAgent">Prénom</Label>
                  <Input type="text" name="prenomAgent" id="prenomAgent" value={prenomAgent} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="nomAgent">Nom</Label>
                  <Input type="text" name="nomAgent" value={nomAgent} id="nomAgent" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup >
                  <Label for="gender">Sexe</Label>
                  <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                    <Option value="M">Masculin</Option>
                    <Option value="F">Feminin</Option>
                  </Select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="contactAgent">Téléphone</Label>
                  <Input type="text" name="contactAgent" value={contactAgent} id="contactAgent" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

              {/*<Col md={6}>
                <FormGroup>
                  <Label for="profession">Poste</Label>
                  <Input type="text" name="profession" value={profession} onChange={this.handleChange} id="profession" placeholder="" />
                </FormGroup>
        </Col>*/}
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup >
                  <Label for="role">Role</Label>
                  <Select className="gx-mr-6 gx-mb-6" value={role} onChange={this.handleChangeRole}>
                    <Option value="ADMIN_ROLE">Administrateur</Option>
                    <Option value="AGENT_ROLE">Agent</Option>
                  </Select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="codAgence">Agences</Label>
                  <InputGroup compact>

                    <Select

                      value={codAgence}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '75%' }}
                      placeholder="Selectionner Agence"
                      optionFilterProp="children"
                      onChange={this.handleChangeAgence}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        agences && agences.length ?
                          agences.map((agence, k) => {
                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                          }) : null
                      }

                    </Select>
                    <Button onClick={this.OpenModalAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>
              {/*<Col md={6}>
                <FormGroup>
                  <Label for="address">Adresse</Label>
                  <Input type="text" name="address" id="address" value={address} onChange={this.handleChange} placeholder="" />
                </FormGroup>
                </Col>*/}

            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="username">Utilisateur</Label>
                  <Input type="text" name="username" id="username" value={username.toUpperCase()} onChange={this.handleChange} placeholder="JEAN" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Mot de passe</Label>
                  <Input type="password" name="password" value={password} id="password" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row form>


            </Row>

          </Form>
        </Modal>

        <Modal
          width={800}
          visible={isModalVisible}
          title="Détails Utilisateur"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" type="danger" onClick={this.handleCancel}>
              Retour
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="prenomAgent">Prénom</Label>
                  <Input disabled type="text" name="prenomAgent" id="prenomAgent" value={prenomAgent} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="nomAgent">Nom</Label>
                  <Input disabled type="text" name="nomAgent" value={nomAgent} id="nomAgent" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup >
                  <Label for="gender">Sexe</Label>
                  <Select disabled className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                    <Option value="M">Masculin</Option>
                    <Option value="F">Feminin</Option>
                  </Select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="contactAgent">Téléphone</Label>
                  <Input disabled type="text" name="contactAgent" value={contactAgent} id="contactAgent" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

              {/*<Col md={6}>
                <FormGroup>
                  <Label for="profession">Poste</Label>
                  <Input type="text" name="profession" value={profession} onChange={this.handleChange} id="profession" placeholder="" />
                </FormGroup>
        </Col>*/}
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup >
                  <Label for="role">Role</Label>
                  <Select disabled className="gx-mr-6 gx-mb-6" value={role} onChange={this.handleChangeRole}>
                    <Option value="ADMIN_ROLE">Administrateur</Option>
                    <Option value="AGENT_ROLE">Agent</Option>
                  </Select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="codAgence">Agences</Label>
                  <InputGroup compact>

                    <Select
                      disabled
                      value={codAgence}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '75%' }}
                      placeholder="Selectionner Agence"
                      optionFilterProp="children"
                      onChange={this.handleChangeAgence}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        agences && agences.length ?
                          agences.map((agence, k) => {
                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                          }) : null
                      }

                    </Select>
                    <Button disabled onClick={this.OpenModalAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>
              {/*<Col md={6}>
                <FormGroup>
                  <Label for="address">Adresse</Label>
                  <Input type="text" name="address" id="address" value={address} onChange={this.handleChange} placeholder="" />
                </FormGroup>
                </Col>*/}

            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="username">Utilisateur</Label>
                  <Input disabled type="text" name="username" id="username" value={username.toUpperCase()} onChange={this.handleChange} placeholder="JEAN" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Mot de passe</Label>
                  <Input disabled type="password" name="password" value={password} id="password" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row form>


            </Row>

          </Form>
        </Modal>

        <Modal
          width={600}
          visible={IsModalAddAgence}
          title="Ajouter une Agence"
          //onOk={this.handleAddOpen}
          onCancel={this.OpenModalAddAgence}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={this.handleAddAgence}>
              Enrégistrer
            </Button>,
            <Button key="back" type="danger" onClick={this.OpenModalAddAgence}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              {/*<Col md={6}>
                <FormGroup>
                  <Label for="codAgence">Code Agence</Label>
                  <Input type="number" name="codAgence" id="codAgence" value={codAgence} onChange={this.handleChange} placeholder="" />
                </FormGroup>
        </Col>*/}
              <Col md={4}>
                <FormGroup>
                  <Label for="codificationAgence">Codification</Label>
                  <Input type="text" name="codificationAgence" value={codificationAgence} id="codificationAgence" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <Label for="nomAgence">Nom Agence</Label>
                  <Input type="text" name="nomAgence" value={nomAgence} onChange={this.handleChange} id="nomAgence" placeholder="" />
                </FormGroup>
              </Col>
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="adrAgence">Adresse</Label>
                  <Input type="text" name="adrAgence" id="adrAgence" value={adrAgence} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="telAgence">Téléphone Agent</Label>
                  <Input type="number" name="telAgence" value={telAgence} id="telAgence" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row form>

              <Col md={6}>
                <FormGroup >
                  <Label for="nomGerant">Nom Gérant</Label>
                  <Input type="text" name="nomGerant" value={nomGerant} id="nomGerant" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="telGerant">Téléphone Agent Gérant</Label>
                  <Input type="number" name="telGerant" value={telGerant} id="telGerant" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="observation">Observation</Label>
              <Input type="text" name="observation" value={observation} id="observation" onChange={this.handleChange} placeholder="" />
            </FormGroup>

          </Form>
        </Modal>


        <Modal
          width={400}
          visible={isOpenDelete}
          title="Suppression Agent"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCloseValidation}
          footer={[

            statutCompte === false &&
            <button className="btn btn-success mt-3 " onClick={this.enableUser} variant="contained">Activer</button>,

            statutCompte === true &&
            <button className="btn btn-info mt-3 " onClick={this.disableUser} variant="contained">Désactiver</button>,



            <Button key="submit" type="primary" loading={loading} onClick={this.handleDeleteAgent}>
              Supprimer
            </Button>,
            <Button key="back" type="danger" onClick={this.handleDeleteAgentOpen}>
              Annuler
            </Button>,

          ]}
        >
          <h5>
            Voulez vous Désactiver/Supprimer l'Agent {prenomAgent} {nomAgent}?
          </h5>
        </Modal>


      </div>
    )
  }
}


export default Agents;
