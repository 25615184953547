import React from "react";
import {Route, Switch} from "react-router-dom";

import Articles from "./Articles/Articles";
import Agences from "./Agences/Agences";
import Fournisseurs from "./Fournisseurs/Fournisseurs";
import Categories from "./Categories/Categories";
import Agents from "./Utilisateurs/Agents";
import Administrateurs from "./Utilisateurs/Administrateurs";

import Clients from "./Clients/Clients"

import asyncComponent from "../../util/asyncComponent";
 
const Fichierbase = ({match}) => (
  <Switch>
    <Route path={`${match.url}/articles`}    component={Articles}/>
    <Route path={`${match.url}/agences`}    component={Agences}/>
    <Route path={`${match.url}/fournisseurs`}   component={Fournisseurs}/>
    <Route path={`${match.url}/categories`}   component={Categories}/>
    <Route path={`${match.url}/utilisateurs/agents`}    component={Agents}/>
    <Route path={`${match.url}/utilisateurs/admin`}    component={Administrateurs}/>

    <Route path={`${match.url}/clients`}    component={Clients}/>

    
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))}/>
  </Switch>
);

export default Fichierbase;
