import React from "react";
import { Card, Divider, Icon, Table, Button, Modal } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Media from 'react-media';


const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: text => <span className="gx-link">{text}</span>,
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
                <span className="gx-link">Action 一 {record.name}</span>
                <Divider type="vertical" />
                <span className="gx-link">Delete</span>
                <Divider type="vertical" />
                <span className="gx-link ant-dropdown-link">
                    More actions <Icon type="down" />
                </span>
            </span>
        ),
    }
];

const data = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
    }
];


class Operations extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        loading: false,
    }


    handleAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,

        });
    };

    handleCancelAdd =() =>{
      this.setState({ isAddModalVisible: !this.state.isAddModalVisible,})
    }

    handleView = (row) => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            etat: row.etat,
            numeroCarte: row.numeroCarte,
            raisonSocial: row.raisonSocial,
            telephone: row.telephone,
            formule: row.formule,
            mois: row.mois,
            montant: row.montant,
            solde: row.solde,
            date: row.date,
            agent: row.agent,


        });
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };


    render() {
        const {
            isModalVisible,
            isAddModalVisible,
            loading,
            etat,
            numeroCarte,
            raisonSocial,
            telephone,
            formule,
            mois,
            montant,
            solde,
            date,
            agent,
        } = this.state


        const clients = [
            {
                "etat": "Validé",
                "raisonSocial": "JSS Sarl",
                "id": 1,
                "numeroCarte": 70156478965,
                "telephone": "66666666",
                "formule": "EVASION",
                "mois": "1 mois",
                "montant": "10000",
                "solde": "55000",
                "date": "12/08/21",
                "agent": "Anicet",
            },
            {
                "etat": "Validé",
                "raisonSocial": "JSS Sarl",
                "id": 2,
                "numeroCarte": 70156478965,
                "telephone": "66666666",
                "formule": "EVASION",
                "mois": "1 mois",
                "montant": "10000",
                "solde": "55000",
                "date": "12/08/21",
                "agent": "Anicet",
            },
            {
                "etat": "Validé",
                "raisonSocial": "JSS Sarl",
                "id": 3,
                "numeroCarte": 70156478965,
                "telephone": "66666666",
                "formule": "EVASION",
                "mois": "1 mois",
                "montant": "10000",
                "solde": "55000",
                "date": "12/08/21",
                "agent": "Anicet",
            },
            {
                "etat": "Validé",
                "raisonSocial": "JSS Sarl",
                "id": 4,
                "numeroCarte": 70156478965,
                "telephone": "66666666",
                "formule": "EVASION",
                "mois": "1 mois",
                "montant": "10000",
                "solde": "55000",
                "date": "12/08/21",
                "agent": "Anicet",
            },
            {
                "etat": "Validé",
                "raisonSocial": "JSS Sarl",
                "id": 5,
                "numeroCarte": 70156478965,
                "telephone": "66666666",
                "formule": "EVASION",
                "mois": "1 mois",
                "montant": "10000",
                "solde": "55000",
                "date": "12/08/21",
                "agent": "Anicet",
            },
            {
                "etat": "Validé",
                "raisonSocial": "JSS Sarl",
                "id": 6,
                "numeroCarte": 70156478965,
                "telephone": "66666666",
                "formule": "EVASION",
                "mois": "1 mois",
                "montant": "10000",
                "solde": "55000",
                "date": "12/08/21",
                "agent": "Anicet",
            },
        ];

        const { SearchBar } = Search;

        const columnsClients = [{
            dataField: 'id',
            text: 'ID'
        }, {
            dataField: 'etat',
            text: 'Etat'
        }, {
            dataField: 'raisonSocial',
            text: 'Raison social '
        },
        {
            dataField: 'numeroCarte',
            text: 'Numéro carte '
        },
        {
            dataField: 'telephone',
            text: 'Téléphone'
        },
        {
            dataField: 'formule',
            text: 'Formule'
        },
        {
            dataField: 'mois',
            text: 'Mois'
        },
        {
            dataField: 'montant',
            text: 'Montant'
        },

        {
            dataField: 'solde',
            text: 'Solde'
        },

        {
            dataField: 'date',
            text: 'Date'
        },
        {
            dataField: 'agent',
            text: 'Agent'
        },

        {
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        <Button
                            type="primary"
                            //fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleView(row)}
                        >
                            <i className="icon icon-custom-view" />
                        </Button>
                    </div>
                );
            },


        }

        ];


        const columnsClientsPhone = [

            {
                dataField: 'raisonSocial',
                text: ' Désignation'
            },


            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                        </div>
                    );
                },


            }

        ];

        const defaultSorted = [{
            dataField: 'raisonSociale',
            order: 'asc'
        }];



        return (
            <div>
                <Card title="Liste des renouvellemnts">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={clients}
                            columns={columnsClients}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 950, width: '250%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAdd}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>
                                            }
                                        </Row>


                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={clients}
                            columns={columnsClientsPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <i className="fa fa-search" style={{ margin: 10 }}></i>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            {/*currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                            <p>ADD</p>
                          </Col>
                    */}

                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>

                <Modal
                    width={600}
                    visible={isAddModalVisible}
                    title="Ajouter Réabonnement"
                    //onOk={this.handleAdd}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,
                       <Button key="submit" type="primary" loading={loading} onClick={this.handleCancel}>
                          Envoyer
                        </Button>,
                         /*<Button
                          key="link"
                          href="https://google.com"
                          type="primary"
                          loading={loading}
                          onClick={this.handleOk}
                        >
                          Search on Google
                        </Button>,*/
                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="numeroCarte">Numéro carte</Label>
                                    <Input type="text" name="numeroCarte" value={numeroCarte} id="numeroCarte" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telephone">Numéro mobile</Label>
                                    <Input type="text" name="telephone" id="telephone" value={telephone} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="formule">Formule</Label>
                                    <Input type="text" name="formule" value={formule} id="formule" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="mois">Mois</Label>
                            <Input type="text" name="mois" value={mois} id="mois" placeholder="" />
                        </FormGroup>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montant">Montant</Label>
                                    <Input type="text" name="montant" id="montant" value={montant} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="solde">Solde</Label>
                                    <Input type="text" name="solde" value={solde} id="solde" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="date">Date</Label>
                                    <Input type="text" name="date" id="date" value={date} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="agent">Agent</Label>
                                    <Input type="text" name="agent" value={agent} id="agent" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>
                </Modal>

                <Modal
                    width={600}
                    visible={isModalVisible}
                    title="Détails Réabonnement"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,
                        /*<Button key="submit" type="primary" loading={loading} onClick={this.handleCancel}>
                          Envoyer
                        </Button>,
                        <Button
                          key="link"
                          href="https://google.com"
                          type="primary"
                          loading={loading}
                          onClick={this.handleOk}
                        >
                          Search on Google
                        </Button>,*/
                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="etat">Etat</Label>
                                    <Input type="text" name="etat" id="etat" value={etat} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="numeroCarte">Numéro carte</Label>
                                    <Input type="text" name="numeroCarte" value={numeroCarte} id="numeroCarte" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="raisonSocial">Raison sociale</Label>
                            <Input type="text" name="raisonSocial" value={raisonSocial} id="raisonSocial" placeholder="" />
                        </FormGroup>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telephone">Numéro mobile</Label>
                                    <Input type="text" name="telephone" id="telephone" value={telephone} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="formule">Formule</Label>
                                    <Input type="text" name="formule" value={formule} id="formule" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="mois">Mois</Label>
                            <Input type="text" name="mois" value={mois} id="mois" placeholder="" />
                        </FormGroup>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montant">Montant</Label>
                                    <Input type="text" name="montant" id="montant" value={montant} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="solde">Solde</Label>
                                    <Input type="text" name="solde" value={solde} id="solde" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="date">Date</Label>
                                    <Input type="text" name="date" id="date" value={date} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="agent">Agent</Label>
                                    <Input type="text" name="agent" value={agent} id="agent" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>
                </Modal>

            </div>
        )
    }
}


export default Operations;
