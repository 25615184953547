import React from "react";
import {Route, Switch} from "react-router-dom";

import Operations from "./Operations/Operations";


import asyncComponent from "../../util/asyncComponent";

const Menu = ({match}) => (
  <Switch>
    <Route path={`${match.url}/operations`}  component={Operations}/> 

    
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))}/>
  </Switch>
);

export default Menu;
