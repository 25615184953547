import React from "react";
import { DatePicker, Row, Col, Table, Button, Card,/* Form, Input, Divider, Icon,  Modal, Select, message, Popconfirm, InputNumber, DatePicker, Radio*/ } from "antd";

//import { FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
//import Media from 'react-media';

import Widget from "components/Widget/index";

import 'moment/locale/fr-ca';

import { services } from '../../services/services';
import { authenticationService } from "../../services/authentication-service";
import { Link } from "react-router-dom";

import Auxiliary from "util/Auxiliary";

import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";

import { numberWithCommas } from '../../helpers/index';
import AmCharts from "@amcharts/amcharts3-react";



const moment = require('moment');

const currentUser = authenticationService.currentUserValue;

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker } = DatePicker;
//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), mo = dateTime.getMonth();
const firstDay = new Date(y, mo, 1);
const lastDay = new Date(y, m + 1, 0);


let date = new Date();
var j = new Array("Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche");
var m = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Nomvembre", "Décembre");
let jour = j[date.getDay() - 1];
let valeurJour = date.getDate();
let mois = m[date.getMonth()]


class Accueil extends React.Component {
  state = {
    articleStock: 0,
    articleStockAll: 0,

    factureAgence: 0,
    factureAgenceAll: 0,

    approvisionnements: [],
    repartitions: [],
    articleSales: [],
    agencesSales: [],
    tendancesVentes: [],

    produitVendu: 0,
    totalVente: 0,
    articleCountSaleAgence: 0,
    articleCountSaleGeneral: 0,
    filtreStartDate: moment(),
    filtreEndDate: moment()
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {

    const startWeekDate = moment().startOf('week').format('YYYY-MM-DD')
    const endWeekDate = moment().endOf('week').format('YYYY-MM-DD')

    const startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
    const endDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
    //console.log("MY DATE", startDate, endDate)
    //const dateEnCours= moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
    //console.log('Date', this.state.dateEnCours)
    services.getDashappro()
      .then(approvisionnements => {
        // console.log('approvisionnements', approvisionnements)
        this.setState({ approvisionnements })
      })

    services.getDashRepart()
      .then(repartitions => {
        // console.log('repartitions', repartitions)
        this.setState({ repartitions })
      })

    services.getDashArticleStock()
      .then(articleStock => {
        //console.log('articleStock', articleStock)
        this.setState({ articleStock })
      })

    services.getDashArticleStockAll()
      .then(articleStockAll => {
        // console.log('articleStockAll', articleStockAll)
        this.setState({ articleStockAll })
      })


    services.getDashArticleSale(startDate, endDate)
      .then(articleSales => {
        // console.log('articleSales', articleSales)
        this.setState({ articleSales: articleSales })
      })

    services.getDashVentesTotale(startDate, endDate)
      .then(totalVente => {
        //  console.log('totalVente', totalVente)
        this.setState({ totalVente: totalVente })
      })

    services.getDashAgenceSale(startDate, endDate)
      .then(agencesSales => {
        //console.log('agencesSales', agencesSales)
        this.setState({ agencesSales: agencesSales })
      })

    services.getDashArticleSaleCount(startDate, endDate)
      .then(produitVendu => {
        // console.log('produitVendu', produitVendu)
        this.setState({ produitVendu: produitVendu })
      })

    services.getDashFactureAgence(startDate, endDate)
      .then(factureAgence => {
        // console.log('factureAgence', factureAgence)
        this.setState({ factureAgence })
      })

    services.getDashFactureAll(startDate, endDate)
      .then(factureAgenceAll => {
        //  console.log('factureAgenceAll', factureAgenceAll)
        this.setState({ factureAgenceAll })
      })

    services.getDashArticleCountSaleAgence(startDate, endDate)
      .then(articleCountSaleAgence => {
        // console.log('articleCountSaleAgence', articleCountSaleAgence)
        this.setState({ articleCountSaleAgence })
      })

    services.getDashArticleCountSaleGeneral(startDate, endDate)
      .then(articleCountSaleGeneral => {
        //console.log('articleCountSaleGeneral', articleCountSaleGeneral)
        this.setState({ articleCountSaleGeneral })
      })


    ///////////
    services.getAllAgences()
      .then(agences => {
        //  console.log('agences', agences)
        let defaultCkeckAgence = agences && agences.length ?
          agences.map((details, k) => {
            return details.codAgence
          }) : []
        this.setState({ agences: agences, defaultCkeckAgence: defaultCkeckAgence })

        let req = {
          startDate: startWeekDate,
          endDate: endWeekDate,
          codeAgence: defaultCkeckAgence,

        }

        services.getTendanceVente(req)
          .then(tendancesVentes => {
            // console.log('tendancesVentes & REQ',req, tendancesVentes)
            this.setState({ tendancesVentes })
          })
      })

  }

  onDateChange = (date, dateString) => {
    // console.log("DATE", date, dateString, dateString[0]);

    const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
    const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')

    services.getDashArticleSale(startDate, endDate)
      .then(articleSales => {
        // console.log('articleSales', articleSales)
        this.setState({ articleSales: articleSales })
      })

    services.getDashVentesTotale(startDate, endDate)
      .then(totalVente => {
        //  console.log('totalVente', totalVente)
        this.setState({ totalVente: totalVente })
      })

    services.getDashAgenceSale(startDate, endDate)
      .then(agencesSales => {
        //console.log('agencesSales', agencesSales)
        this.setState({ agencesSales: agencesSales })
      })

    services.getDashArticleSaleCount(startDate, endDate)
      .then(produitVendu => {
        // console.log('produitVendu', produitVendu)
        this.setState({ produitVendu: produitVendu })
      })

    services.getDashFactureAgence(startDate, endDate)
      .then(factureAgence => {
        // console.log('factureAgence', factureAgence)
        this.setState({ factureAgence })
      })

    services.getDashFactureAll(startDate, endDate)
      .then(factureAgenceAll => {
        //  console.log('factureAgenceAll', factureAgenceAll)
        this.setState({ factureAgenceAll })
      })

    services.getDashArticleCountSaleAgence(startDate, endDate)
      .then(articleCountSaleAgence => {
        // console.log('articleCountSaleAgence', articleCountSaleAgence)
        this.setState({ articleCountSaleAgence })
      })

    services.getDashArticleCountSaleGeneral(startDate, endDate)
      .then(articleCountSaleGeneral => {
        //console.log('articleCountSaleGeneral', articleCountSaleGeneral)
        this.setState({ articleCountSaleGeneral })
      })


    ///////////
    services.getAllAgences()
      .then(agences => {
        //  console.log('agences', agences)
        let defaultCkeckAgence = agences && agences.length ?
          agences.map((details, k) => {
            return details.codAgence
          }) : []
        this.setState({ agences: agences, defaultCkeckAgence: defaultCkeckAgence })

        let req = {
          startDate: startDate,
          endDate: endDate,
          codeAgence: defaultCkeckAgence,

        }

        services.getTendanceVente(req)
          .then(tendancesVentes => {
            //console.log('tendancesVentes & REQ', req, tendancesVentes)
            this.setState({ tendancesVentes })
          })
      })
    //console.log("MISE A JOUR", dateString[0], dateString[1])
    this.setState({ filtreStartDate: dateString[0], filtreEndDate: dateString[1] })

  }


  render() {
    const {
      articleStock,
      //articleStockAll,

      factureAgence,
      factureAgenceAll,

      approvisionnements,
      repartitions,
      articleSales,
      produitVendu,
      agencesSales,
      totalVente,
      tendancesVentes,
      articleCountSaleGeneral,
      articleCountSaleAgence,

      filtreStartDate,
      filtreEndDate,



    } = this.state

    /*const totalVente = articleSales && articleSales.length ?
      articleSales.reduce(function (prev, cur) {
        return prev + parseInt(cur.puUtilise) * parseInt(cur.qtCommander) + parseInt(((parseInt(cur.puUtilise) * parseInt(cur.qtCommander)) * (parseInt(cur.tauxTva) / 100)))
      }, 0) : 0*/

    /*const produitVendu = articleSales && articleSales.length ?
      articleSales.reduce(function (prev, cur) {
        return prev + parseInt(cur.qtCommander)
      }, 0) : 0*/

    /*const columns = [
      {
        title: 'Currency',
        dataIndex: 'currency',
      },
      {
        title: 'Rate (USD)',
        dataIndex: 'rate',
      },
      {
        title: 'DATE',
        dataIndex: 'date',
      },
      {
        title: 'FEE',
        dataIndex: 'fee',
        render: (text) => {
          return <span className="gx-text-red">{text}</span>
        },
      }
    ];*/

    /*const data = [
      {
        key: '1',
        currency: '0.24 BTC',
        rate: '1 BTC = $740',
        date: '08.10.17',
        fee: '-$2.33'
      },
      {
        key: '2',
        currency: '0.34 RPL',
        rate: '1 RPL = $80.2',
        date: '08.03.17',
        fee: '-$1.23'
      },
      {
        key: '3',
        currency: '0.24 BTC',
        rate: '1 BTC = $740',
        date: '07.29.17',
        fee: '-$3.22'
      },
      {
        key: '4',
        currency: '0.22 BTC',
        rate: '1 BTC = $740',
        date: '07.28.17',
        fee: '-$3.22'
      },
      {
        key: '5',
        currency: '0.74 LTE',
        rate: '1 LTE = $99',
        date: '05.22.17',
        fee: '-$2.21'
      },
      {
        key: '6',
        currency: 'ALIA DORTIVAL 0.74 LTE',
        rate: 'PC PORTATIF TOSHIBA 500GBPC PORTATIF TOSH',
        date: '1000',
        fee: '7500000'
      }

    ];
*/
    let sortedData = agencesSales.sort(
      (p1, p2) => (p1.totalChiffre < p2.totalChiffre) ? 1 : (p1.totalChiffre > p2.totalChiffre) ? -1 : 0);

    let dataPointVente = tendancesVentes && tendancesVentes.length ?
      tendancesVentes.map((details, k) => {
        return {
          "date": moment(new Date(details.date), 'YYYY-MM-DD').format('DD-MM-YYYY'),
          "montant": details.total
        } //details && details.article ? details.article.codPro : ''
      }) : []

    const config = {
      "type": "serial",
      "theme": "light",
      //"handDrawn": true,
      //"handDrawScatter": 3,
      /*"legend": {
        "useGraphSettings": true,
        "markerSize": 12,
        "valueWidth": 0,
        "verticalGap": 0
      },*/
      "dataProvider": agencesSales,
      "valueAxes": [
        {
          "id": "chiffreAxis",
          "minorGridAlpha": 0.08,
          "minorGridEnabled": true,
          //"position": "top",
          "axisAlpha": 0,
          "title": "Chiffre d'Affaire",
          "fillColorsField": "#008080",

        },
        /*{
          "id": "qteAxis",
          "axisAlpha": 0,
          "gridAlpha": 0,
          "position": "left",
          "title": "Quantité"
        }*/
      ],
      "startDuration": 1,
      "graphs": [{
        "balloonText": "<span style='font-size:13px;'>[[title]]:  [[category]] <b>[[value]]</b> </span>",
        "title": "Chiffre d'Affaire",
        "type": "column",
        "fillAlphas": 0.8,
        "valueAxis": "chiffreAxis",
        "valueField": "totalChiffre",
        "fillColorsField": "color",
      }, /*{
        "balloonText": "<span style='font-size:13px;'>[[title]]:  [[category]] <b>[[value]]</b></span>",
        "bullet": "round",
        "bulletBorderAlpha": 1,
        "bulletColor": "#FFFFFF",
        "useLineColorForBulletBorder": true,
        "fillAlphas": 0,
        "lineThickness": 2,
        "lineAlpha": 1,
        "bulletSize": 7,
        "title": "Quantités Vendus",
        "valueField": "totalQuantite",
        "valueAxis": "qteAxis"

      }*/],
      "rotate": true,
      "categoryField": "nomAgence",
      "categoryAxis": {
        "gridPosition": "start"
      },
      "export": {
        "enabled": false
      }

    };

    const configPoint = {
      "type": "serial",
      "theme": "light",
      "marginRight": 40,
      "marginLeft": 40,
      "autoMarginOffset": 20,
      "mouseWheelZoomEnabled": true,
      "dataDateFormat": "DD-MM-YYYY",
      "valueAxes": [{
        "id": "v1",
        "axisAlpha": 0,
        "position": "left",
        "ignoreAxisWidth": true
      }],
      "balloon": {
        "borderThickness": 1,
        "shadowAlpha": 0
      },
      "graphs": [{
        "id": "g1",
        /*"balloon": {
            "drop": true,
            "adjustBorderColor": false,
            "color": "#ffffff"
        },*/
        "bullet": "round",
        "bulletBorderAlpha": 1,
        "bulletColor": "#FFFFFF",
        "bulletSize": 5,
        "hideBulletsCount": 50,
        "lineThickness": 2,
        "title": "red line",
        "useLineColorForBulletBorder": true,
        "valueField": "montant",
        "balloonText": `<span style='font-size:18px;'>[[date]]: [[montant]] F</span>`
      }],
      "chartScrollbar": {
        "graph": "g1",
        "oppositeAxis": false,
        "offset": 30,
        "scrollbarHeight": 80,
        "backgroundAlpha": 0,
        "selectedBackgroundAlpha": 0.1,
        "selectedBackgroundColor": "#888888",
        "graphFillAlpha": 0,
        "graphLineAlpha": 0.5,
        "selectedGraphFillAlpha": 0,
        "selectedGraphLineAlpha": 1,
        "autoGridCount": true,
        "color": "#AAAAAA"
      },
      "chartCursor": {
        "pan": true,
        "valueLineEnabled": true,
        "valueLineBalloonEnabled": true,
        "cursorAlpha": 1,
        "cursorColor": "#258cbb",
        "limitToGraph": "g1",
        "valueLineAlpha": 0.2,
        "valueZoomable": true
      },
      "valueScrollbar": {
        "oppositeAxis": false,
        "offset": 50,
        "scrollbarHeight": 10
      },
      "categoryField": "date",
      "categoryAxis": {
        "parseDates": true,
        "dashLength": 1,
        "minorGridEnabled": true
      },
      "export": {
        "enabled": true
      },
      "dataProvider": dataPointVente
    };

    let totalCA = agencesSales && agencesSales.length ?
      agencesSales.reduce(function (prev, cur) {
        return prev + parseInt(cur.totalChiffre)
      }, 0) : 0
    if (totalCA === 0) {
      totalCA = 0
    }

    const columnsApprovisionnements = [
      {
        key: 'codeEntre',
        title: 'N° Cmde',
        dataIndex: 'codeEntre',
      },
      {
        key: 'dateEntree',
        title: 'Date',
        dataIndex: 'dateEntree',
        render: (text) => {
          return <span className="gx-text-red">{text /*moment(new Date(text), 'DD/MM/YYYY').format('DD/MM/YYYY')*/}</span>
        },
      },
      {
        key: 'fournisseur.raisonSociale',
        title: 'Fournisseur',
        dataIndex: 'fournisseur.raisonSociale',
      },
      {
        key: 'id',
        title: 'Montant',
        dataIndex: 'montantAp',
        render: (text) => {
          return <span className="gx-text-red">{numberWithCommas(parseInt(text))}</span>
        },
      }
    ];

    const columnsRepartitions = [
      {
        key: 'id',
        title: 'N°',
        dataIndex: 'id',
      },
      {
        key: 'dateRepartition',
        title: 'Date',
        dataIndex: 'dateRepartition',
      },
      {
        key: 'agences.nomAgence',
        title: 'Agences',
        dataIndex: 'agences.nomAgence',
      },
      {
        key: 'observation',
        title: 'Observation',
        dataIndex: 'observation',
        render: (text) => {
          return <span className="gx-text-red">{text}</span>
        },
      }
    ];

    let columnsVentesAgences = [

      {
        key: 'nomAgence',
        title: 'Agence',
        dataIndex: `nomAgence`,

      },
      {
        key: 'totalChiffre',
        title: 'Montant',
        dataIndex: 'totalChiffre',
        render: (text, record) => {
          //console.log('TEST', text)
          return <span className="gx-text-red text-center">
            {
              (numberWithCommas(parseInt(record.totalChiffre)))

            }
          </span>
        },
      },
      {
        key: 'totalQuantite',
        title: 'Qté Vendus',
        dataIndex: 'totalQuantite',
        render: (text, record) => {
          //console.log('TEST', text)
          return <span className="gx-text-red text-center">
            {
              (numberWithCommas(parseInt(record.totalQuantite)))

            }
          </span>
        },

      },

      {
        key: 'totalCA',
        title: '%',
        //dataIndex: 'puUtilise',
        render: (text, record) => {
          //console.log('TEST', text)
          return <span className="gx-text-red text-center">
            {
              ((parseInt(record.totalChiffre) * 100) /( (totalCA === 0)? 1 : totalCA)).toFixed(2)


            } %
          </span>
        },
      }
    ];

    let columnsVente = [];
    if (currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") {
      columnsVente = [

        {
          key: 'agences.nomAgence',
          title: 'Agence',
          dataIndex: `agences.nomAgence`,

        },
        {
          key: 'article.designation',
          title: 'Désignation',
          dataIndex: 'article.designation',
        },
        {
          key: 'qtCommander',
          title: 'Qté Commandée',
          dataIndex: 'qtCommander',
          render: (text, record) => {
            //console.log('TEST', text)
            return <span className="gx-text-red text-center">
              {
                (numberWithCommas(parseInt(record.qtCommander)))

              }
            </span>
          },

        },

        {
          key: 'puUtilise',
          title: 'Montant',
          //dataIndex: 'puUtilise',
          render: (text, record) => {
            //console.log('TEST', text)
            return <span className="gx-text-red text-center">
              {
                numberWithCommas((parseInt(record.puUtilise) * parseInt(record.qtCommander) + parseInt(((parseInt(record.puUtilise) * parseInt(record.qtCommander)) * (parseInt(record.tauxTva) / 100)))))

              }
            </span>
          },
        }
      ];
    } else {
      columnsVente = [


        {
          key: 'article.designation',
          title: 'Désignation',
          dataIndex: 'article.designation',
        },
        {
          key: 'qtCommander',
          title: 'Qté Commandée',
          dataIndex: 'qtCommander',
          render: (text, record) => {
            //console.log('TEST', text)
            return <span className="gx-text-red text-center">
              {
                numberWithCommas((parseInt(record.qtCommander)))

              }
            </span>
          },
        },

        {
          key: 'puUtilise',
          title: 'Montant',
          dataIndex: 'puUtilise',
          render: (text, record) => {
            //console.log('TEST', text)
            return <span className="gx-text-red text-center">
              {
                numberWithCommas((parseInt(record.puUtilise) * parseInt(record.qtCommander) + parseInt(((parseInt(record.puUtilise) * parseInt(record.qtCommander)) * (parseInt(record.tauxTva) / 100)))))

              }
            </span>
          },
        }
      ];

    }

    let filtrePeriodeText = `${filtreStartDate}  au   ${filtreEndDate}`

    let evolutioVenteTitle = (new Date(filtreStartDate).getTime() == new Date(filtreEndDate).getTime()) ? "EVOLUTION DES VENTES DE LA SEMAINE" : ` EVOLUTION DES VENTES DU ${filtrePeriodeText}`
    let evolutioChiffreTitle = (new Date(filtreStartDate).getTime() == new Date(filtreEndDate).getTime()) ? "EVOLUTION DU CHIFFRE D'AFFAIRE DU JOUR" : ` EVOLUTION DU CHIFFRE D'AFFAIRE DU ${filtrePeriodeText}`



    return (
      <Auxiliary>
        <Row>
          <Col span={24}>
            <div className="gx-card">
              <div className="gx-card-body">
                <Row>
                  <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                    <div className="gx-wel-ema gx-pt-xl-2">
                      <h1 className="gx-mb-3">Bienvenue {currentUser ? currentUser.firstName : ''} {currentUser ? currentUser.lastName.toUpperCase() : ''}!</h1>
                      <p className="gx-fs-sm gx-text-uppercase">Vous avez pour {(new Date(filtreStartDate).getTime() == new Date(filtreEndDate).getTime()) ? "aujourd'hui" : ` la période du ${filtrePeriodeText}`}:</p>
                      {/*<ul className="gx-list-group">
                      <li>
                        <Icon type="message"/>
                        <span>5 Unread messages</span>
                      </li>
                      <li>
                        <Icon type="mail"/>
                        <span>2 Pending invitations</span>
                      </li>
                      <li>
                        <Icon type="profile"/>
                        <span>7 Due tasks</span>
                      </li>
                      <li>
                        <Icon type="bell"/>
                        <span>3 Other notifications</span>
                      </li>
                    </ul>*/}
                    </div>
                  </Col>

                  <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
                    <h3 className="m-b-0 ">{jour} {valeurJour} {mois}, {date.getFullYear()} </h3>
                    <RangePicker className="gx-w-100"
                      defaultValue={[moment(filtreStartDate, dateFormat), moment(filtreEndDate, dateFormat)]}
                      format={dateFormat}
                      onChange={this.onDateChange}
                      ranges={{
                        "Aujourd'hui": [moment(), moment()],
                        'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                        'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                        'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                      }}
                    />
                    <span>Filtrer par Période</span>
                  </Col>

                  <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">

                  </Col>
                </Row>

              </div>
            </div>
          </Col>
        </Row>

        {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") ?
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="orange" icon="tasks" title={factureAgence} subTitle="Total Factures Agence" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="red" icon="files" title={factureAgenceAll} subTitle="Total Factures Général" />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="cyan" icon="diamond" title={articleCountSaleAgence} subTitle="Articles Vendus Agence" />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="teal" icon="team" title={articleCountSaleGeneral} subTitle="Articles Vendus Général" />
            </Col>
            {/*<Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="cyan" icon="diamond" title={articleStock} subTitle="Stock Agence" />
            </Col>
            
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <IconWithTextCard cardColor="teal" icon="team" title={articleStockAll} subTitle="Stock Général" />
                  </Col>*/}

          </Row> :

          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <IconWithTextCard cardColor="cyan" icon="diamond" title={articleStock} subTitle="Produits en Stock" />
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <IconWithTextCard cardColor="orange" icon="tasks" title={factureAgence} subTitle="Total Factures Agences" />
            </Col>

          </Row>
        }

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="gx-card-overview">
              <div className="gx-overview-row">
                <div className="gx-line-indicator-col">
                  <h2 className="h4 gx-card-title">Chiffre d'affaire du {(new Date(filtreStartDate).getTime() == new Date(filtreEndDate).getTime()) ? "jour" : `${filtrePeriodeText}`}   par Agence</h2>

                  <Widget styleName="gx-bg-dark-primary">
                    <div className="gx-flex-row gx-justify-content-center gx-mb-3 gx-mb-md-4">
                      <i className={`icon icon-refer gx-fs-xlxl gx-text-white`} />
                    </div>
                    <div className="gx-text-center">
                      <h2 className="h3 gx-mb-3 gx-text-white">{currentUser && currentUser.agence ? currentUser.agence.nomAgence : ''}</h2>
                      {/*<p className="gx-text-white gx-mb-3">Vous avez pour aujourd'hui {produitVendu} produits vendu issu
                        de {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                          factureAgenceAll

                        }
                        {(currentUser.agence.enCours === true && currentUser.slug === "AGENT_ROLE" || currentUser.agence.enCours !== true && currentUser.slug === "ADMIN_ROLE" || currentUser.agence.enCours !== true && currentUser.slug === "AGENT_ROLE") &&
                          factureAgence

                        } factures avec un montant total de {numberWithCommas(parseInt(totalVente))} F Cfa.</p>
                      */}

                      {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") ?
                        <Link to="/gestion/facture/principal">
                          <Button size="large" className="gx-btn-secondary gx-mt-md-5 gx-mb-1">Factures du Jour</Button>
                        </Link> :

                        <Link to="/gestion/facture/detailsPrincipal">
                          <Button size="large" className="gx-btn-secondary gx-mt-md-5 gx-mb-1">Factures du Jour</Button>
                        </Link>
                      }
                    </div>
                  </Widget>
                </div>

                <div className="gx-overview-description">
                  <div className="gx-revenu gx-revenu-total">
                    <h1>{numberWithCommas(parseInt(totalVente))} F CFA</h1>
                    <span className="gx-fs-md">Chiffre d'Affaire Globale</span>
                  </div>

                  <div className="gx-revenu">
                    <div className="gx-revenu-row">
                      <div className="gx-revenu-col">
                        <h3>{produitVendu}</h3>
                        <span className="gx-fs-sm">Produits vendus</span>
                      </div>

                      <div className="gx-revenu-col">
                        {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") ?
                          <h3>{factureAgenceAll || 0}</h3>
                          : null
                        }
                        {(currentUser.agence.enCours === true && currentUser.slug === "AGENT_ROLE" || currentUser.agence.enCours !== true && currentUser.slug === "ADMIN_ROLE" || currentUser.agence.enCours !== true && currentUser.slug === "AGENT_ROLE") ?
                          <h3>{factureAgence}</h3>
                          : null
                        }
                        <span className="gx-fs-sm">Factures</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gx-map-col">

                  <div className="gx-table-responsive">
                    {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                      <Table className="gx-table-no-bordered" columns={columnsVentesAgences} dataSource={agencesSales} pagination={false} bordered={false}
                        size="small" />
                    }

                    {(currentUser.slug === "AGENT_ROLE" || currentUser.agence.enCours === true && currentUser.slug !== "ADMIN_ROLE") &&
                      <Table className="gx-table-no-bordered" columns={columnsVente} dataSource={articleSales} pagination={false} bordered={false}
                        size="small" />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Card className="gx-card" title={evolutioChiffreTitle}>
              <div className="App">
                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
              </div>
            </Card>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Card className="gx-card" title={evolutioVenteTitle}>
              <div className="App">
                <AmCharts.React style={{ width: "100%", height: "500px" }} options={configPoint} />
              </div>
            </Card>
          </Col>
        </Row>

        {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") ?
          <Row>
            <Col xl={12} lg={24} md={12} sm={24} xs={24}>
              <Widget styleName="gx-order-history"
                title={

                  <h2 className="h4 gx-text-capitalize gx-mb-0">
                    Approvisionnements en cours</h2>

                } extra={
                  <Link to="/gestion/stock/approvisionnement">
                    <p className="gx-text-primary gx-mb-0 gx-pointer">Voir Plus</p>
                  </Link>
                }>
                <div className="gx-table-responsive">
                  <Table className="gx-table-no-bordered" columns={columnsApprovisionnements} dataSource={approvisionnements} pagination={false} bordered={false}
                    size="small" />
                </div>
              </Widget>
            </Col>
            <Col xl={12} lg={24} md={12} sm={24} xs={24}>
              <Widget styleName="gx-order-history"
                title={
                  <h2 className="h4 gx-text-capitalize gx-mb-0">
                    Répartition en Cours</h2>
                } extra={
                  <Link to="/gestion/stock/repartitions">
                    <p className="gx-text-primary gx-mb-0 gx-pointer">Voir Plus</p>
                  </Link>
                }>
                <div className="gx-table-responsive">
                  <Table className="gx-table-no-bordered" columns={columnsRepartitions} dataSource={repartitions} pagination={false} bordered={false}
                    size="small" />
                </div>
              </Widget>
            </Col>

            {/*} <Col xl={9} lg={24} md={24} sm={24} xs={24}>
            <SendMoney />
          </Col>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Widget styleName="gx-order-history"
              title={
                <h2 className="h4 gx-text-capitalize gx-mb-0">
                  Répartition en Cours</h2>
              } extra={
                <p className="gx-text-primary gx-mb-0 gx-pointer">Voir Plus</p>
              }>
              <div className="gx-table-responsive">
                <Table className="gx-table-no-bordered" columns={columns} dataSource={data} pagination={false} bordered={false}
                  size="small" />
              </div>
            </Widget>
          </Col>*/}

          </Row>
          : null
        }

        {/*<Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <ChartCard prize="$9,626" title="23" icon="bitcoin"
              children={<ResponsiveContainer width="100%" height={75}>
                <AreaChart data={increamentData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <Tooltip />
                  <defs>
                    <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                      <stop offset="5%" stopColor="#163469" stopOpacity={0.9} />
                      <stop offset="95%" stopColor="#FE9E15" stopOpacity={0.9} />
                    </linearGradient>
                  </defs>
                  <Area dataKey='price' strokeWidth={0} stackId="2" stroke='#4D95F3' fill="url(#color3)"
                    fillOpacity={1} />
                </AreaChart>
              </ResponsiveContainer>}
              styleName="up" desc="Bitcoin Price" />
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <ChartCard prize="$7,831" title="07" icon="etherium"
              children={<ResponsiveContainer width="100%" height={75}>
                <AreaChart data={increamentData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <Tooltip />
                  <defs>
                    <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                      <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9} />
                      <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9} />
                    </linearGradient>
                  </defs>
                  <Area dataKey='price' type='monotone' strokeWidth={0} stackId="2" stroke='#4D95F3'
                    fill="url(#color4)"
                    fillOpacity={1} />
                </AreaChart>
              </ResponsiveContainer>}
              styleName="up" desc="Etherium Price" />
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <ChartCard prize="$1,239" title="08" icon="ripple"
              children={<ResponsiveContainer width="100%" height={75}>
                <AreaChart data={increamentData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <Tooltip />
                  <defs>
                    <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#e81a24" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#FEEADA" stopOpacity={0.8} />
                    </linearGradient>
                  </defs>
                  <Area dataKey='price' strokeWidth={0} stackId="2" stroke='#FEEADA' fill="url(#color5)"
                    fillOpacity={1} />
                </AreaChart>
              </ResponsiveContainer>}
              styleName="down" desc="Ripple Price" />
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <ChartCard prize="$849" title="47" icon="litcoin"
              children={<ResponsiveContainer width="100%" height={75}>

                <LineChart data={lineData}
                  margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                  <Tooltip />
                  <Line dataKey="price" stroke="#038FDE" dot={{ stroke: '#FEA931', strokeWidth: 2 }} />
                </LineChart>
              </ResponsiveContainer>}
              styleName="down" desc="Litecoin Price" />
          </Col>
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <Portfolio />
          </Col>
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <BalanceHistory />
          </Col>

          <Col xl={9} lg={24} md={24} sm={24} xs={24}>
            <SendMoney />
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <RewardCard />
          </Col>
          <Col xl={9} lg={12} md={12} sm={24} xs={24}>
            <CurrencyCalculator />
          </Col>

          <Col xl={15} lg={24} md={24} sm={24} xs={24}>
            <CryptoNews />
          </Col>
          <Col xl={9} lg={24} md={24} sm={24} xs={24}>
            <DownloadMobileApps />
            <OrderHistory />
          </Col>
        </Row>*/}

      </Auxiliary>
    )
  }
}


export default Accueil;
