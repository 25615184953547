
import { handleResponse } from './handle-response';
import { authHeader } from './auth-header'
import { base_url } from '../env'


export const services = {
    getArticles,
    addArticle,
    updateArticle,
    deleteArticle,

    getAgences,
    addAgence,
    updateAgence,
    deleteAgence,
    getAllAgences,

    getFournisseurs,
    addFournisseur,
    updateFournisseur,
    deleteFournisseur,

    getCategories,
    addCategorie,
    updateCategorie,
    deleteCategorie,

    getApprovisionnements,
    addApprovisionnement,
    updateApprovisionnement,
    deleteApprovisionnement,
    validerApprovisionnement,

    getRepartitions,
    addRepartition,
    updateRepartition,
    deleteRepartition,
    validerRepartition,

    getFactures,
    getDetailsFactures,
    getDetailsFacturesGlobale,
    getFacturesGlobale,
    addFacture,
    getFacturesClients,

    getStocksGeneral,
    getStocksAgence,

    getAgents,
    addAgent,
    updateAgent,
    deleteAgent,


    getAdmin,
    addAdmin,
    updateAdmin,
    deleteAdmin,

    getTva,

    getClients,
    addClient,
    updateClient,
    deleteClient,

    addUser,
    deleteUser,
    updateUser,
    disableUser,
    enableUser,
    getCurrentUser,


    updateProfil,
    getProfil,
    updatePassword,


    getDashappro,
    getDashRepart,
    getDashArticleStock,
    getDashArticleStockAll,
    getDashArticleSale,
    getDashAgenceSale,
    getDashVentesTotale,
    getDashArticleSaleCount,
    getDashFactureAgence,
    getDashFactureAll,
    getDashArticleCountSaleAgence,
    getDashArticleCountSaleGeneral,

    getStart,

    getCommandes,
    getHistoriqueCommande,

    getStartAgence,
    getTopMode,
    getTendanceVente,
    getTotalClient,
    getTotalQteVendu,
    getTopArticles,
    getPointDuJour,
    getArticlesVendus,
    getChiffreAffaireClient,

    activityLog,
}

function activityLog(ip, location, departement, longitude, latitude, action){ 
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ ip, location, departement, longitude, latitude, action})
    };
    return fetch(`${base_url}/api/activitylog`, requestOptions)
        .then(handleResponse);
}

function getArticlesVendus( startDate, endDate, codAgence) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/chart/article-vendu?startDate=${startDate}&endDate=${endDate}&codAgence=${codAgence}`, requestOptions)
        .then(handleResponse);
}

function getChiffreAffaireClient( startDate, endDate, codAgence) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/chart/chiffre-clients?startDate=${startDate}&endDate=${endDate}&codAgence=${codAgence}`, requestOptions)
        .then(handleResponse);
}

function getPointDuJour( startDate, endDate,) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/commandes/pointsJourPrldg?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

function getStartAgence(startDate, endDate, codeAgence='') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/chart/agence?startDate=${startDate}&endDate=${endDate}&codeAgence=${codeAgence}`, requestOptions)
        .then(handleResponse);
}

function getTopMode(startDate, endDate, codeAgence='') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/chart/mode?startDate=${startDate}&endDate=${endDate}&codeAgence=${codeAgence}`, requestOptions)
        .then(handleResponse);
}

function getTendanceVente(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/chart/vente`, requestOptions)
        .then(handleResponse);
}

function getTotalClient(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/chart/clients`, requestOptions)
        .then(handleResponse);
}

function getTotalQteVendu(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/chart/quantites`, requestOptions)
        .then(handleResponse);
}

function getTopArticles(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/chart/articles`, requestOptions)
        .then(handleResponse);
}

function getHistoriqueCommande(startDate, endDate, dateEnCours='', etatCmde='FACTUREE') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/commandes/historique?startDate=${startDate}&endDate=${endDate}&dateEnCours=${dateEnCours}&etatCmde=${etatCmde}`, requestOptions)
        .then(handleResponse);
}

function getCommandes(start = 0, end = 10, dateEnCours='', etatCmde='EN COURS') { /*&etatCmde=${etatCmde}*/
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/commandes?start=${start}&end=${end}&dateEnCours=${dateEnCours}`, requestOptions)
        .then(handleResponse);
}

// START
function getStart( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/`, requestOptions)
        .then(handleResponse);
}

// DASHBOARD
function getDashappro( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/appro`, requestOptions)
        .then(handleResponse);
}

function getDashRepart( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/repart`, requestOptions)
        .then(handleResponse);
}

function getDashArticleStock( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/stock`, requestOptions)
        .then(handleResponse);
}

function getDashArticleStockAll( ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/stock/all`, requestOptions)
        .then(handleResponse);
}

function getDashArticleSale( startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/sale?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

function getDashVentesTotale( startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/sale/total?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}


function getDashAgenceSale(startDate, endDate ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/agence/sale?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

function getDashArticleSaleCount(startDate, endDate ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/sale/count?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

function getDashFactureAgence( startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/facture/agence?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}



function getDashFactureAll( startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/facture/all?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

function getDashArticleCountSaleAgence(startDate, endDate ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/sale/count/agence?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

function getDashArticleCountSaleGeneral( startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/dash/article/sale/count/general?startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

//TVA

function getTva() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/tva`, requestOptions)
        .then(handleResponse);
}


//ARTICLES
function addArticle(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/articles`, requestOptions)
        .then(handleResponse);
}

function updateArticle(idArticle, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/articles/${idArticle}`, requestOptions)
        .then(handleResponse);
}

function getArticles(search = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/articles?search=${search}`, requestOptions)
        .then(handleResponse);
}

function deleteArticle(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/articles/${code}`, requestOptions)
        .then(handleResponse);
}



//AGENCES
function addAgence(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/agences`, requestOptions)
        .then(handleResponse);
}

function updateAgence(idAgence, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/agences/${idAgence}`, requestOptions)
        .then(handleResponse);
}

function getAgences(search = '', commercialFiltre= '', communeFiltre='', statut= 'PROSPECT') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/agences?search=${search}&commercialFiltre=${commercialFiltre}&communeFiltre=${communeFiltre}&statut=${statut}`, requestOptions)
        .then(handleResponse);
}

function getAllAgences() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/agences/all`, requestOptions)
        .then(handleResponse);
}

function deleteAgence(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/agences/${code}`, requestOptions)
        .then(handleResponse);
}

//FOURNISSEURS
function addFournisseur(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/fournisseurs`, requestOptions)
        .then(handleResponse);
}

function updateFournisseur(idAgence, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/fournisseurs/${idAgence}`, requestOptions)
        .then(handleResponse);
}

function getFournisseurs(search = '', commercialFiltre= '', communeFiltre='', statut= 'PROSPECT') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/fournisseurs?search=${search}&commercialFiltre=${commercialFiltre}&communeFiltre=${communeFiltre}&statut=${statut}`, requestOptions)
        .then(handleResponse);
}

function deleteFournisseur(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/fournisseurs/${code}`, requestOptions)
        .then(handleResponse);
}



// CATEGORIES
function getCategories(search = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/categories?search=${search}`, requestOptions)
        .then(handleResponse);
}
function addCategorie(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/categories`, requestOptions)
        .then(handleResponse);
}

function updateCategorie(idCategorie, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/categories/${idCategorie}`, requestOptions)
        .then(handleResponse);
}


function deleteCategorie(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/categories/${code}`, requestOptions)
        .then(handleResponse);
}



// APPROVISIONNEMENTS
function getApprovisionnements(etatCmde = 'EN COURS', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/approvisionnements?etatCmde=${etatCmde}`, requestOptions)
        .then(handleResponse);
}
function addApprovisionnement(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/approvisionnements`, requestOptions)
        .then(handleResponse);
}

function updateApprovisionnement(codeEntre, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/approvisionnements/${codeEntre}`, requestOptions)
        .then(handleResponse);
}


function deleteApprovisionnement(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/approvisionnements/${code}`, requestOptions)
        .then(handleResponse);
}

function validerApprovisionnement(code) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/approvisionnements/validation/${code}`, requestOptions)
        .then(handleResponse);
}


// REPARTITIONS
function getRepartitions(etatCmde = 'EN COURS', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/repartitions?etatCmde=${etatCmde}`, requestOptions)
        .then(handleResponse);
}
function addRepartition(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/repartitions`, requestOptions)
        .then(handleResponse);
}

function updateRepartition(codeEntre, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/repartitions/${codeEntre}`, requestOptions)
        .then(handleResponse);
}


function deleteRepartition(repartitionId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/repartitions/${repartitionId}`, requestOptions)
        .then(handleResponse);
}

function validerRepartition(code) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/repartitions/validation/${code}`, requestOptions)
        .then(handleResponse);
}



// FACTURE
function getFactures(dateEnCours, agence, startDate, endDate,  ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/factures?dateEnCours=${dateEnCours}&agence=${agence}&startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}

function getDetailsFactures(dateEnCours, startDate, endDate, agence ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/factures/details?dateEnCours=${dateEnCours}&startDate=${startDate}&endDate=${endDate}&agence=${agence}`, requestOptions)
        .then(handleResponse);
}

function getDetailsFacturesGlobale(codAgence = '', dateEnCours='' ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/factures/globale/details?codAgence=${codAgence}&dateEnCours=${dateEnCours}`, requestOptions)
        .then(handleResponse);
}

function getFacturesGlobale( codAgence = '', dateEnCours='') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/factures/globale?codAgence=${codAgence}&dateEnCours=${dateEnCours}`, requestOptions)
        .then(handleResponse);
}
function addFacture(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/factures`, requestOptions)
        .then(handleResponse);
}


function getFacturesClients(codClient, codAgence, startDate, endDate,  ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/chart/facture-clients?codClient=${codClient}&codAgence=${codAgence}&startDate=${startDate}&endDate=${endDate}`, requestOptions)
        .then(handleResponse);
}


// STOCK AGENCE

function getStocksGeneral(codAgence = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/stockgeneral?codAgence=${codAgence}`, requestOptions)
        .then(handleResponse);
}

function getStocksAgence(search = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/stockagences?search=${search}`, requestOptions)
        .then(handleResponse);
}


 // AGENTS
function getAgents(codAgence = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/users?codAgence=${codAgence}`, requestOptions)
        .then(handleResponse);
}
function addAgent(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users`, requestOptions)
        .then(handleResponse);
}

function updateAgent(id, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users/${id}`, requestOptions)
        .then(handleResponse);
}


function deleteAgent(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/users/${code}`, requestOptions)
        .then(handleResponse);
}



// ADMIN
function getAdmin(codAgence = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/admin?codAgence=${codAgence}`, requestOptions)
        .then(handleResponse);
}
function addAdmin(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/admin`, requestOptions)
        .then(handleResponse);
}

function updateAdmin(id, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/admin/${id}`, requestOptions)
        .then(handleResponse);
}


function deleteAdmin(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/admin/${code}`, requestOptions)
        .then(handleResponse);
}


// CLIENTS
function getClients(search = '', ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/client?search=${search}`, requestOptions)
        .then(handleResponse);
}
function addClient(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/client`, requestOptions)
        .then(handleResponse);
}

function updateClient(id, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/client/${id}`, requestOptions)
        .then(handleResponse);
}


function deleteClient(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/client/${code}`, requestOptions)
        .then(handleResponse);
}


//USER 2

function addUser(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users`, requestOptions)
        .then(handleResponse);
}

function disableUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify()
    };
    return fetch(`${base_url}/api/users/disable/${user}`, requestOptions)
        .then(handleResponse);
}
function enableUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify()
    };
    return fetch(`${base_url}/api/users/enable/${user}`, requestOptions)
        .then(handleResponse);
}

function getCurrentUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/users/current`, requestOptions)
        .then(handleResponse);
}

//USER
function deleteUser(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/users/${code}`, requestOptions)
        .then(handleResponse);
}

function updateUser(idUser, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/users/${idUser}`, requestOptions)
        .then(handleResponse);
}

//ROle
function getRoles(search = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        
    };
    return fetch(`${base_url}/api/roles?search=${search}`, requestOptions)
        .then(handleResponse);
}
function addRole(req) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/roles`, requestOptions)
        .then(handleResponse);
}

function updateRole(idUser, req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/roles/${idUser}`, requestOptions)
        .then(handleResponse);
}

function deleteRole(code) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${base_url}/api/roles/${code}`, requestOptions)
        .then(handleResponse);
}



//PROFIL
function updateProfil(req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/profil`, requestOptions)
        .then(handleResponse);
}

function getProfil() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${base_url}/api/profil`, requestOptions)
        .then(handleResponse);
}

function updatePassword(req) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(req)
    };
    return fetch(`${base_url}/api/profil/password`, requestOptions)
        .then(handleResponse);
}





