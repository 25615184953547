import React from "react";
import { DatePicker, Card, Divider, Select, Input, Form } from "antd";

import { Table, Row, Col, FormGroup, } from 'reactstrap';
import Media from 'react-media';

import { use_logiciel } from '../../env'

import { services } from '../../services/services'
import { numberWithCommas } from '../../helpers/index';

import moment from "moment";
import AmCharts from "@amcharts/amcharts3-react";
import { authenticationService } from "../../services/authentication-service";

const currentUser = authenticationService.currentUserValue
//console.log("currentUser", currentUser)

const { MonthPicker, RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
//const monthFormat = 'YYYY/MM';

//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);

const FormItem = Form.Item;



class TopAgences extends React.Component {
    state = {
        startAgences: [],
        startDate: firstDay,
        endDate: lastDay,
        codAgence: '',
        formLayout: 'horizontal',
        //stateDate: moment(),
       // endDate: moment(),


    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const startDate = moment(new Date(firstDay), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(lastDay), 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.setState({startDate: startDate, endDate: endDate})
       // console.log("DATE INPUT", startDate, endDate)
        services.getStartAgence(startDate, endDate)
            .then(startAgences => {
              //  console.log('startAgences', startAgences)
                this.setState({ startAgences })
            })

        services.getAllAgences()
            .then(agences => {
            //    console.log('agences', agences)
                this.setState({ agences })
            })
    }



    //SELECTION AGENCE LORS CREATION OU MISE A JOUR USER
    handleChangeAgence = (value) => {
        this.setState({ codAgence: value })
    }


    //FILTRE POUR TABLEAU UTILISATEUR PAR AGENCE
    handleChangeAgence2 = (value) => {
        this.setState({ codAgenceChange: value, codAgence: value })

        const startDate = moment(new Date(this.state.startDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(this.state.endDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        //console.log("DATE INPUT2", startDate, endDate, value)

        services.getStartAgence(startDate, endDate, value)
            .then(startAgences => {
               // console.log('startAgences2', startAgences)
                this.setState({ startAgences: startAgences })
            })
    }

    onDateChange = (date, dateString) => {
        // console.log("DATE", date, dateString, dateString[0]);

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
       // console.log("DATE INPUT3", startDate, endDate, this.state.codAgence)

        services.getStartAgence(startDate, endDate, this.state.codAgence)
            .then(startAgences => {
               // console.log('startAgences2', startAgences)
                this.setState({ startAgences: startAgences, startDate: startDate, endDate: endDate, })
            })
    }


    render() {
        const {
            agences,

            codAgence,
            codAgenceChange,
            startAgences,
            startDate,
            endDate,
            formLayout

        } = this.state

        const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;

        const Option = Select.Option;

        let data = startAgences && startAgences.length ?
            startAgences.map((details, k) => {
                return {
                    "agence": details.nomAgence,
                    "chiffre": details.total,
                    //"color": "#008080"
                } //details && details.article ? details.article.codPro : ''
            }) : []
        //console.log("DETAILS", data)

        let sortedData = data.sort(
            (p1, p2) => (p1.chiffre < p2.chiffre) ? 1 : (p1.chiffre > p2.chiffre) ? -1 : 0);
        // console.log("sortedData", sortedData)

        let totalChiffre = data && data.length ?
            data.reduce(function (prev, cur) {
                return prev + parseInt(cur.chiffre)
            }, 0) : 0
        if (totalChiffre === 0) {
            totalChiffre = 1
        }
        //console.log("totalChiffre", totalChiffre)


        const config = {
            "theme": "light",
            "type": "serial",
            "dataProvider": data
            /*[
            data,
            {
              "agence": "OK",
              "chiffre": 23.5
            }, 
            ],
            "valueAxes": [{
              "title": "TOP AGENCES"
            }]*/,
            "graphs": [{
                "balloonText": "Chiffre d´Affaire [[category]]:[[value]]",
                "fillAlphas": 1,
                "lineAlpha": 0.2,
                "title": "Chiffre d'Affaire",
                "type": "column",
                "valueField": "chiffre",
                "fillColorsField": "color",
                
            }],
            "depth3D": 20,
            "angle": 30,
            "rotate": true,
            "categoryField": "agence",
            "categoryAxis": {
                "gridPosition": "start",
                "fillAlpha": 0.05,
                "position": "left"
            },
            "export": {
                "enabled": false
            }
        };


        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?
                    <Card title="Top Agences">

                        <Media query="(min-width: 600px)" render={() =>
                        (

                            <div>
                                <Row >
                                    <Col md={5} style={{ display: 'flex', alignItems: 'baseline' }}>
                                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                    </Col>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="mr-2">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black", fontWeight: "bold" }}><span>Filtrer par Période</span></h6>
                                        </FormGroup>
                                        <FormGroup >
                                            <Select

                                                value={codAgenceChange}
                                                showSearch
                                                //style={{width: 200}}
                                                style={{ width: '140%' }}
                                                placeholder="Filtrer par Agence"
                                                optionFilterProp="children"
                                                onChange={this.handleChangeAgence2}
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleBlur}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                <Option value=''>TOUTES</Option>

                                                {
                                                    agences && agences.length ?
                                                        agences.map((agence, k) => {
                                                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                        }) : null
                                                }

                                            </Select>
                                            <h6 className="mr-3" style={{ color: "black", fontWeight: "bold" }}><span>Filtrer par Agence</span></h6>
                                        </FormGroup>


                                        {/*use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&


                                        <button style={{ marginLeft: 3 }}
                                            className="btn bg-primary text-light rounded"
                                            onClick={this.handleAddOpen}>
                                            NOUVEAU <i className="icon icon-add" />
                                        </button>
                                        */}
                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <Card className="gx-card" title=" Top Agence suivant le CA">
                                            <div className="App gx-border-bottom gx-pb-3 gx-mb-3">
                                                <Table class="table table-striped table-responsive">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Agence</th>
                                                            <th scope="col">Chiffre d'Affaire</th>
                                                            <th scope="col">%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data && data.length ?
                                                                data.map((item, k) => {
                                                                    return (
                                                                        <tr key={k}>

                                                                            <th scope="row">
                                                                                <span>{item && item.agence}</span>
                                                                            </th>
                                                                            <td>
                                                                                <span>{numberWithCommas(item && item.chiffre)}</span>
                                                                            </td>
                                                                            <td>{parseFloat((item && item.chiffre * 100) / totalChiffre).toFixed(2)}%</td>
                                                                        </tr>

                                                                    );
                                                                })
                                                                :
                                                                <div className="search-not-found text-center">
                                                                    <div>

                                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                                    </div>
                                                                </div>
                                                        }


                                                    </tbody>

                                                </Table>
                                                

                                                {/*<FormItem className="mt-3" label="Total CA"  {...formItemLayout}>
                                                <Input disabled type="text" value={`${numberWithCommas(totalChiffre)} F CFA`} id="totalTva" placeholder="" />
                                                </FormItem>*/}


                                            </div>
                                            <h3 style={{ color: "red" }}>Total CA: {(totalChiffre === 1)? 0 : `${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                            
                                        </Card>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>

                                </Row>



                            </div>


                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (


                            <div>
                                <Row >


                                    <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="mr-2">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black", fontWeight: "bold" }}><span>Filtrer par Période</span></h6>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>

                                        <FormGroup >
                                            <Select

                                                value={codAgenceChange}
                                                showSearch
                                                //style={{width: 200}}
                                                style={{ width: '290%' }}
                                                placeholder="Filtrer par Agence"
                                                optionFilterProp="children"
                                                onChange={this.handleChangeAgence2}
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleBlur}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                <Option value=''>TOUTES</Option>

                                                {
                                                    agences && agences.length ?
                                                        agences.map((agence, k) => {
                                                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                        }) : null
                                                }

                                            </Select>
                                            <h6 className="mr-3" style={{ color: "black", fontWeight: "bold" }}><span>Filtrer par Agence</span></h6>
                                        </FormGroup>

                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Card className="gx-card" title="Top Agence suivant le Chiffre d'Affaire">
                                            <div className="App">
                                                <Table class="table table-striped table-responsive">
                                                    <thead className="thead-light">
                                                        <tr>

                                                            <th scope="col">Agence</th>
                                                            <th scope="col">Chiffre d'Affaire</th>
                                                            <th scope="col">%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data && data.length ?
                                                                data.map((item, k) => {
                                                                    return (
                                                                        <tr key={k}>

                                                                            <th scope="row">
                                                                                <span>{item && item.agence}</span>
                                                                            </th>
                                                                            <td>
                                                                                <span>{numberWithCommas(item && item.chiffre)}</span>
                                                                            </td>
                                                                            <td>{parseFloat((item && item.chiffre * 100) / totalChiffre).toFixed(2)}%</td>
                                                                        </tr>

                                                                    );
                                                                })
                                                                :
                                                                <div className="search-not-found text-center">
                                                                    <div>

                                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                                    </div>
                                                                </div>
                                                        }


                                                    </tbody>
                                                </Table>
                                                <FormItem className="mt-3" label="Total CA:"  >
                                                    <Input disabled type="number" value={(totalChiffre === 1)? 0 :totalChiffre} id="totalTva" placeholder="" />
                                                </FormItem>

                                            </div>
                                        </Card>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>



                            </div>


                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }



            </div>
        )
    }
}


export default TopAgences;
