import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

import Components from "./components/index";
import CustomViews from "./customViews/index";
import Extensions from "./extensions/index";
import ExtraComponents from "./extraComponents/index";
import InBuiltApps from "./inBuiltApps/index";
import SocialApps from "./socialApps/index";
import Main from "./main/index";
import Documents from "./documents/index";


import Menu from "./Menu/index";
//import Gestion from "./gestion/index"
import Gestion from "./gestion/index";
import Fichierbase from "./Fichierbase/index" ;
import Profile from "./Profile/Profile" ;
import Accueil from "./Accueil/Accueil";
import TopAgences from "./Accueil/TopAgences"
import TendancesVentes from "./Accueil/TendancesVentes"
import TotalClients from "./Accueil/TotalClients"
import QuantiteVendues from "./Accueil/QuantiteVendues"
import TopArticles from "./Accueil/TopArticles"
import TopModePaiement from "./Accueil/TopModePaiement"
import ArticlesVendus from "./Accueil/ArticlesVendus";
import DetailsVentes from "./Accueil/DetailsVentes";
import ChiffreAffaireClient from "./Accueil/ChiffreAffaireClients";
import FactureClient from "./Accueil/FactureClient";



const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main}/>
      <Route path={`${match.url}components`} component={Components}/>
      <Route path={`${match.url}custom-views`} component={CustomViews}/>
      <Route path={`${match.url}extensions`} component={Extensions}/>
      <Route path={`${match.url}extra-components`} component={ExtraComponents}/>
      <Route path={`${match.url}in-built-apps`} component={InBuiltApps}/>
      <Route path={`${match.url}social-apps`} component={SocialApps}/>
      <Route path={`${match.url}documents`} component={Documents}/>

      
      <Route path={`${match.url}gestion`} component={Gestion} />
      <Route path={`${match.url}base`} component={Fichierbase} />
      <Route path={`${match.url}menu`} component={Menu} />
      <Route path={`${match.url}profile`} component={Profile} />

      <Redirect exact from={`${match.url}/`} to={`${match.url}/accueil`}/>
      <Route path='/accueil/dashboard' component={asyncComponent(() => import('./Accueil/Accueil'))}/>
      <Route path='/accueil/topAgences' component={asyncComponent(() => import('./Accueil/TopAgences'))}/>
      <Route path='/accueil/tendancesVentes' component={asyncComponent(() => import('./Accueil/TendancesVentes'))}/>
      <Route path='/accueil/totalClients' component={asyncComponent(() => import('./Accueil/TotalClients'))}/>
      <Route path='/accueil/quantiteVendues' component={asyncComponent(() => import('./Accueil/QuantiteVendues'))}/>
      <Route path='/accueil/topArticles' component={asyncComponent(() => import('./Accueil/TopArticles'))}/>
      <Route path='/accueil/topMode' component={asyncComponent(() => import('./Accueil/TopModePaiement'))}/>
      <Route path='/accueil/articlesVendus' component={asyncComponent(() => import('./Accueil/ArticlesVendus'))}/>
      <Route path='/accueil/DetailsVentes' component={asyncComponent(() => import('./Accueil/DetailsVentes'))}/>
      <Route path='/accueil/ChiffreAffaireClients' component={asyncComponent(() => import('./Accueil/ChiffreAffaireClients'))}/>
      <Route path='/accueil/FactureClient' component={asyncComponent(() => import('./Accueil/FactureClient'))}/>
      <Route path='/accueil/dashboard' component={asyncComponent(() => import('./Accueil/Accueil'))}/>
      <Route path='/accueil/dashboard' component={asyncComponent(() => import('./Accueil/Accueil'))}/>
      <Route path='/accueil/dashboard' component={asyncComponent(() => import('./Accueil/Accueil'))}/>
      <Route path='/accueil/dashboard' component={asyncComponent(() => import('./Accueil/Accueil'))}/>
      <Route path='/accueil/dashboard' component={asyncComponent(() => import('./Accueil/Accueil'))}/>
      {/*<Route path={`${match.url}/accueil`} component={asyncComponent(() => import('./Accueil/Accueil'))}/>*/}

    </Switch> 
  </div> 
);

export default App;
