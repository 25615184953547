import React from "react";
import { DatePicker, Card, Divider, Select, Input, Form } from "antd";

import { Table, Row, Col, FormGroup, } from 'reactstrap';
import Media from 'react-media';

import { use_logiciel } from '../../env'

import { services } from '../../services/services'
import { numberWithCommas } from '../../helpers/index';

import moment from "moment";
import AmCharts from "@amcharts/amcharts3-react";
import { authenticationService } from "../../services/authentication-service";

const currentUser = authenticationService.currentUserValue
//console.log("currentUser", currentUser)

const { MonthPicker, RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
//const monthFormat = 'YYYY/MM';

//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);

const FormItem = Form.Item;



class TopModePaiement extends React.Component {
    state = {
        topModes: [],
        startDate: firstDay,
        endDate: lastDay,
        codAgence: '',
        formLayout: 'horizontal',


    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const startDate = moment(new Date(firstDay), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(lastDay), 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getTopMode(startDate, endDate)
            .then(topModes => {
                //console.log('topModes', topModes)
                this.setState({ topModes })
            })

        /*services.getAllAgences()
            .then(agences => {
                console.log('agences', agences)
                this.setState({ agences })
            })*/
    }



    //SELECTION AGENCE LORS CREATION OU MISE A JOUR USER
    handleChangeAgence = (value) => {
        this.setState({ codAgence: value })
    }


    //FILTRE POUR TABLEAU UTILISATEUR PAR AGENCE
    handleChangeAgence2 = (value) => {
        this.setState({ codAgenceChange: value })

        const startDate = moment(new Date(this.state.startDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(this.state.endDate), 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getTopMode(startDate, endDate, value)
            .then(topModes => {
               // console.log('topModes2', topModes)
                this.setState({ topModes: topModes })
            })
    }

    onDateChange = (date, dateString) => {
        // console.log("DATE", date, dateString, dateString[0]);

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //console.log("END START", startDate, endDate )
        services.getTopMode(startDate, endDate, this.state.codAgenceChange)
            .then(topModes => {
              //  console.log('topModes2', topModes)
                this.setState({ topModes: topModes, startDate: dateString[0], endDate: dateString[1], })
            })
    }


    render() {
        const {
            agences,

            codAgence,
            codAgenceChange,
            topModes,
            startDate,
            endDate,
            formLayout

        } = this.state

        const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;

        const Option = Select.Option;

        /*let data = topModes && topModes.length ?
            topModes.map((details, k) => {
                return {
                    "agence": details.nomAgence,
                    "total": details.total
                } //details && details.article ? details.article.codPro : ''
            }) : []*/
        //console.log("DETAILS", data)

        let sortedData = topModes.sort(
            (p1, p2) => (p1.total < p2.total) ? 1 : (p1.total > p2.total) ? -1 : 0);
        // console.log("sortedData", sortedData)

        let montantTotal = topModes && topModes.length ?
            topModes.reduce(function (prev, cur) {
                return prev + parseInt(cur.total)
            }, 0) : 0
        if (montantTotal === 0) {
            montantTotal = 1
        }
        //console.log("montantTotal", montantTotal)


        const config = {
            "type": "pie",
            "theme": "light",
            "dataProvider": topModes,/*[{
              "title": "New",
              "value": 4852
            }, {
              "title": "Returning",
              "value": 9899
            }],*/
            "titleField": "libelle",
            "valueField": "total",
            "labelRadius": 5,
        
            "radius": "42%",
            "innerRadius": "60%",
            "labelText": "[[title]]",
            "export": {
              "enabled": false
            }
          };


        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?
                    <Card title="Top Mode de Paiement">

                        <Media query="(min-width: 600px)" render={() =>
                        (

                            <div>
                                <Row >
                                    <Col md={5} style={{ display: 'flex', alignItems: 'baseline' }}>
                                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                    </Col>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Période</span></h6>
                                        </FormGroup>
                                        {/*<FormGroup >
                                            <Select

                                                value={codAgenceChange}
                                                showSearch
                                                //style={{width: 200}}
                                                style={{ width: '140%' }}
                                                placeholder="Filtrer par Agence"
                                                optionFilterProp="children"
                                                onChange={this.handleChangeAgence2}
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleBlur}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                <Option value=''>TOUTES</Option>

                                                {
                                                    agences && agences.length ?
                                                        agences.map((agence, k) => {
                                                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                        }) : null
                                                }

                                            </Select>
                                            <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                        </FormGroup>
                                            */}

                                        {/*use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&


                                        <button style={{ marginLeft: 3 }}
                                            className="btn bg-primary text-light rounded"
                                            onClick={this.handleAddOpen}>
                                            NOUVEAU <i className="icon icon-add" />
                                        </button>
                                        */}
                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <Card className="gx-card" title="Top Mode de Paiement">
                                            <div className="App">
                                                <Table class="table table-striped table-responsive">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">Modes</th>
                                                            <th scope="col">Montant</th>
                                                            <th scope="col">%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            topModes && topModes.length ?
                                                                topModes.map((item, k) => {
                                                                    return (
                                                                        <tr key={k}>

                                                                            <th scope="row">
                                                                                <span>{item && item.libelle}</span>
                                                                            </th>
                                                                            <td>
                                                                                <span>{numberWithCommas(item && item.total)}</span>
                                                                            </td>
                                                                            <td>{parseFloat((item && item.total * 100) / montantTotal).toFixed(2)}%</td>
                                                                        </tr>

                                                                    );
                                                                })
                                                                :
                                                                <div className="search-not-found text-center">
                                                                    <div>

                                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                                    </div>
                                                                </div>
                                                        }


                                                    </tbody>

                                                </Table>
                                                <hr />
                                                <h3 style={{ color: "red" }}>Montant Total {`${(montantTotal === 1)? 0 : numberWithCommas(montantTotal)} F CFA`}</h3>

                                                {/*<FormItem className="mt-3" label="Total CA"  {...formItemLayout}>
                                                <Input disabled type="text" value={`${numberWithCommas(montantTotal)} F CFA`} id="totalTva" placeholder="" />
                                                </FormItem>*/}


                                            </div>
                                        </Card>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>

                                </Row>



                            </div>


                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (


                            <div>
                                <Row >


                                    <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="mr-2">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Période</span></h6>
                                        </FormGroup>
                                    </Col>

                                   {/* <Col md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>

                                        <FormGroup >
                                            <Select

                                                value={codAgenceChange}
                                                showSearch
                                                //style={{width: 200}}
                                                style={{ width: '290%' }}
                                                placeholder="Filtrer par Agence"
                                                optionFilterProp="children"
                                                onChange={this.handleChangeAgence2}
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleBlur}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                <Option value=''>TOUTES</Option>

                                                {
                                                    agences && agences.length ?
                                                        agences.map((agence, k) => {
                                                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                        }) : null
                                                }

                                            </Select>
                                            <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                        </FormGroup>

                                            </Col> */}


                                </Row>

                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Card className="gx-card" title="Top Mode de Paiement">
                                            <div className="App">
                                                <Table class="table table-striped table-responsive">
                                                    <thead className="thead-light">
                                                        <tr>

                                                            <th scope="col">Modes</th>
                                                            <th scope="col">Montant</th>
                                                            <th scope="col">%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            topModes && topModes.length ?
                                                                topModes.map((item, k) => {
                                                                    return (
                                                                        <tr key={k}>

                                                                            <th scope="row">
                                                                                <span>{item && item.libelle}</span>
                                                                            </th>
                                                                            <td>
                                                                                <span>{numberWithCommas(item && item.total)}</span>
                                                                            </td>
                                                                            <td>{parseFloat((item && item.total * 100) / montantTotal).toFixed(2)}%</td>
                                                                        </tr>

                                                                    );
                                                                })
                                                                :
                                                                <div className="search-not-found text-center">
                                                                    <div>

                                                                        <p>{"Aucune donnée disponible, Veuillez changer les Options du fitrage!"}</p>
                                                                    </div>
                                                                </div>
                                                        }


                                                    </tbody>
                                                </Table>
                                                <FormItem className="mt-3" label="Montant Total"  >
                                                    <Input disabled type="number" value={(montantTotal === 1)? 0 :montantTotal} id="totalTva" placeholder="" />
                                                </FormItem>

                                            </div>
                                        </Card>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>



                            </div>


                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }



            </div>
        )
    }
}


export default TopModePaiement;
