import React from "react";
import { Card, Divider, Icon, Table, Button, Modal, Select, message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Media from 'react-media';

import { services } from '../../../services/services'
import { use_logiciel } from '../../../env'




class Fournisseurs extends React.Component {
  state = {
    isModalVisible: false,
    isAddModalVisible: false,
    isUpdateModalVisible: false,
    loading: false,
    fournisseurs: [],

    usuel: "1",
    codTyp: "1",
    raisonSociale: '',
    codeCat: '',
    emailFour: '',
    numIfu: '',

    adrFour: '',
    contactFour: '',
    siteWeb: '',
    observation: '',
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  componentDidMount() {
    services.getFournisseurs()
      .then(fournisseurs => {
       // console.log('fournisseurs', fournisseurs)
        this.setState({ fournisseurs })
      })


  }
  handleAddOpen = () => {
    this.setState({ isAddModalVisible: !this.state.isAddModalVisible, });
  };

  handleCancelAdd = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      codFour: '',
      numIfu: '',
      raisonSociale: '',
      adrFour: '',
      contactFour: '',
      emailFour: '',
      siteWeb: '',
      observation: '',
    })
  }

  handleAddFournisseur = () => {
    const {
      codFour,
      numIfu,
      raisonSociale,
      adrFour,
      contactFour,
      emailFour,
      siteWeb,
      observation,
    } = this.state


    if (raisonSociale === '') {
      message.warning('Veuillez préciser la Raison sociale', 5)
      return;
    }
    /*if (codFour === '') {
      message.warning('Veuillez préciser le code Fournisseur', 5)
      return;
    }*/
    this.setState({ loading: true })

    let req = {
      // codFour,
      numIfu,
      raisonSociale: raisonSociale.toUpperCase(),
      adrFour,
      contactFour,
      emailFour,
      siteWeb,
      observation,
      //raisonSociale: raisonSociale.toUpperCase(),

    }
    services.addFournisseur(req)
      .then(result => {
        //console.log(result)
        this.setState({
          codFour: '',
          numIfu: '',
          raisonSociale: '',
          adrFour: '',
          contactFour: '',
          emailFour: '',
          siteWeb: '',
          observation: '',
          isAddModalVisible: !this.state.isAddModalVisible,
          loading: false,

        })
        message.success('Fournisseurs ajouter avec succès', 10)

        services.getFournisseurs()
          .then(fournisseurs => {
            this.setState({ fournisseurs })
          })

      })

      .catch((err) => {
        message.error(err, 10)
      });
  }

  handleUpdateFournisseur = () => {
    const {
      //idFournisseur,
      codFour,
      numIfu,
      raisonSociale,
      adrFour,
      contactFour,
      emailFour,
      siteWeb,

    } = this.state


    if (raisonSociale === '') {
      message.warning('Veuillez préciser la Raison sociale', 5)
      return;
    }
    /*if (codFour === '') {
      message.warning('Veuillez précise le Code Fournisseur', 5)
      return;
    }*/

    let req = {
      //idFournisseur,
      codFour,
      numIfu,
      raisonSociale: raisonSociale.toUpperCase(),
      adrFour,
      contactFour,
      emailFour,
      siteWeb,


    }
    services.updateFournisseur(codFour, req)
      .then(result => {
        //console.log(result)
        this.setState({
          idFournisseur: '',
          codFour: '',
          numIfu: '',
          raisonSociale: '',
          adrFour: '',
          contactFour: '',
          emailFour: '',
          siteWeb: '',
          observation: '',
          isUpdateModalVisible: !this.state.isUpdateModalVisible,

        })
        message.success('Fournisseurs modifier avec succès', 10)

        services.getFournisseurs()
          .then(fournisseurs => {
            this.setState({ fournisseurs })
          })

      })

      .catch(() => {
        message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
      });
  }

  handleDelete = (row) => {
    this.setState({
      codFour: row.codFour,
      raisonSociale: row.raisonSociale,
    })

  }

  handleDeleteFournisseur = () => {

    const { codFour } = this.state

    services.deleteFournisseur(codFour)
      .then(del => {
        //console.log('delete user', del)
        message.success('Fournisseur supprimer avec succès', 10)
        services.getFournisseurs()
          .then(fournisseurs => {
            this.setState({ fournisseurs })
          })

      })
      .catch((err) => {
        message.error(err, 10)
      });

  }

  handleUpdateOpen = (row) => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      //idFournisseur: row.id,
      codFour: row.codFour,
      numIfu: row.numIfu,
      raisonSociale: row.raisonSociale,
      adrFour: row.adrFour,
      contactFour: row.contactFour,
      emailFour: row.emailFour,
      siteWeb: row.siteWeb,
      observation: row.observation,

    })

  }

  handleCancelUpdate = () => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      codFour: '',
      numIfu: '',
      raisonSociale: '',
      adrFour: '',
      contactFour: '',
      emailFour: '',
      siteWeb: '',
      observation: '',
    })
  }


  handleView = (row) => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      codFour: row.codFour,
      numIfu: row.numIfu,
      raisonSociale: row.raisonSociale,
      adrFour: row.adrFour,
      contactFour: row.contactFour,
      emailFour: row.emailFour,
      siteWeb: row.siteWeb,

    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      codFour: '',
      numIfu: '',
      raisonSociale: '',
      adrFour: '',
      contactFour: '',
      emailFour: '',
      siteWeb: '',
      observation: '',
    });
  }


  render() {
    const {
      isModalVisible,
      loading,
      codFour,
      numIfu,
      raisonSociale,
      adrFour,
      contactFour,
      emailFour,
      siteWeb,
      observation,

      fournisseurs,


      isAddModalVisible,
      isUpdateModalVisible
    } = this.state


    const Option = Select.Option;

    const { SearchBar } = Search;

    const columnsFournisseurs = [ {
      dataField: 'codFour',
      text: ' Code'
    },
    {
      dataField: 'raisonSociale',
      text: ' Désignation '
    },
    {
      dataField: 'numIfu',
      text: 'Ifu'
    },
    {
      dataField: 'contactFour',
      text: 'Téléphone'
    },
    {
      dataField: 'adrFour',
      text: ' Adresse'
    },


    {
      dataField: '',
      text: 'Action',
      headerAlign: 'center',
      sort: true,
      headerAttrs: { width: 50 },
      align: 'center',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          < div
            style={{ textAlign: "center" }} className="d-flex">
            <Button
              type="primary"
              //fullWidth
              variant="contained"
              color="primary"
              size="sm"
              //className="btn btn-success btn-sm"
              onClick={() => this.handleView(row)}
            >
              <i className="icon icon-custom-view" />
            </Button>
            <Button
            hidden= { use_logiciel === 'NO' ? false : true}
              type="primary"
              //fullWidth
              variant="contained"
              color="danger"
              size="sm"
              //className="btn btn-success btn-sm"
              onClick={() => this.handleUpdateOpen(row)}
            >
              <i className="icon icon-edit" />
            </Button>
            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Fournisseur?" onConfirm={this.handleDeleteFournisseur} onCancel={this.cancelDelete} okText="Oui"
              cancelText="Non">
              <Button
              hidden= { use_logiciel === 'NO' ? false : true}
                type="danger"
                //fullWidth
                variant="contained"
                color="primary"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleDelete(row)}
              >
                <i className="icon icon-alert-new" />
              </Button>

            </Popconfirm>

          </div>
        );
      },

    }

    ];


    const columnsFournisseursPhone = [

      {
        dataField: 'raisonSociale',
        text: ' Raison sociale'
      },


      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
              hidden= { use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="sm"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Fournisseur?" onConfirm={this.handleDeleteFournisseur} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                hidden= { use_logiciel === 'NO' ? false : true}
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="sm"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>

            </div>
          );
        },

      }

    ];

    const defaultSorted = [{
      dataField: 'raisonSociale',
      order: 'asc'
    }];



    return (
      <div>
        <Card title="Liste des Fournisseurs">

          <Media query="(min-width: 600px)" render={() =>
          (
            <ToolkitProvider
              keyField="id"
              data={fournisseurs}
              columns={columnsFournisseurs}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>

                      }
                    </Row>


                    <BootstrapTable
                      {...props.baseProps}
                      //bootstrap4
                      wrapperClasses="table-responsive"
                      //selectRow={ selectRow }
                      //defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                      striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                    //filter={filterFactory()}
                    />

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />


          <Media query="(max-width: 599px)" render={() =>
          (

            <ToolkitProvider
              keyField="id"
              data={fournisseurs}
              columns={columnsFournisseursPhone}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <i className="fa fa-search" style={{ margin: 10 }}></i>

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>
                      }

                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      //bootstrap4
                      wrapperClasses="table-responsive"
                      //selectRow={ selectRow }
                      //defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                      striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                    //filter={filterFactory()}
                    />

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />
        </Card>


        <Modal
          width={800}
          visible={isAddModalVisible}
          title="Ajouter un Fournisseur"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelAdd}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={this.handleAddFournisseur}>
              Envoyer
            </Button>,
            <Button key="back" type="danger" onClick={this.handleCancelAdd}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              {/* <Col md={6}>
                <FormGroup>
                  <Label for="codFour">Code Fournisseur</Label>
                  <Input type="number" name="codFour" id="codFour" value={codFour} onChange={this.handleChange} placeholder="" />
                </FormGroup>
        </Col>*/}
              <Col md={6}>
                <FormGroup>
                  <Label for="numIfu">IFU</Label>
                  <Input maxLength={13} type="text" name="numIfu" value={numIfu} id="numIfu" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="raisonSociale">Raison sociale</Label>
                  <Input type="text" name="raisonSociale" value={raisonSociale} onChange={this.handleChange} id="raisonSociale" placeholder="" />
                </FormGroup>
              </Col>
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="adrFour">Adresse</Label>
                  <Input type="text" name="adrFour" id="adrFour" value={adrFour} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="contactFour">Téléphone</Label>
                  <Input type="text" name="contactFour" value={contactFour} id="contactFour" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row form>

              <Col md={6}>
                <FormGroup >
                  <Label for="emailFour">Email</Label>
                  <Input type="text" name="emailFour" value={emailFour} id="emailFour" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="siteWeb">Site web</Label>
                  <Input type="text" name="siteWeb" value={siteWeb} id="siteWeb" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

          </Form>
        </Modal>

        <Modal
          width={800}
          visible={isUpdateModalVisible}
          title="Modifier le Fournisseur"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelUpdate}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateFournisseur}>
              Modifier
            </Button>,
            <Button key="back" type="danger" onClick={this.handleCancelUpdate}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codFour">Code Fournisseur</Label>
                  <Input disabled type="number" name="codFour" id="codFour" value={codFour} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="numIfu">IFU</Label>
                  <Input type="text" name="numIfu" value={numIfu} id="numIfu" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="raisonSociale">Raison sociale</Label>
              <Input type="text" name="raisonSociale" value={raisonSociale} onChange={this.handleChange} id="raisonSociale" placeholder="" />
            </FormGroup>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="adrFour">Adresse</Label>
                  <Input type="text" name="adrFour" id="adrFour" value={adrFour} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="contactFour">Téléphone</Label>
                  <Input type="text" name="contactFour" value={contactFour} id="contactFour" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row form>

              <Col md={6}>
                <FormGroup >
                  <Label for="emailFour">Email</Label>
                  <Input type="text" name="emailFour" value={emailFour} id="emailFour" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="siteWeb">Site web</Label>
                  <Input type="text" name="siteWeb" value={siteWeb} id="siteWeb" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>


          </Form>
        </Modal>

        <Modal
          width={800}
          visible={isModalVisible}
          title="Détails Fournisseur"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" type="danger" onClick={this.handleCancel}>
              Retour
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codFour">Code Fournisseur</Label>
                  <Input disabled type="number" name="codFour" id="codFour" value={codFour} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="numIfu">IFU</Label>
                  <Input disabled type="text" name="numIfu" value={numIfu} id="numIfu" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="raisonSociale">Raison sociale</Label>
              <Input disabled type="text" name="raisonSociale" value={raisonSociale} onChange={this.handleChange} id="raisonSociale" placeholder="" />
            </FormGroup>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="adrFour">Adresse</Label>
                  <Input disabled type="text" name="adrFour" id="adrFour" value={adrFour} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="contactFour">Téléphone</Label>
                  <Input disabled type="text" name="contactFour" value={contactFour} id="contactFour" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row form>

              <Col md={6}>
                <FormGroup >
                  <Label for="emailFour">Email</Label>
                  <Input disabled type="text" name="emailFour" value={emailFour} id="emailFour" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="siteWeb">Site web</Label>
                  <Input disabled type="text" name="siteWeb" value={siteWeb} id="siteWeb" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

          </Form>
        </Modal>

      </div>
    )
  }
}


export default Fournisseurs;
