import React from "react";
import { DatePicker, Card, Divider, Select, Input, Form, Checkbox } from "antd";

import { Table, Row, Col, FormGroup, } from 'reactstrap';
import Media from 'react-media';

import { use_logiciel } from '../../env'

import { services } from '../../services/services'
import { numberWithCommas } from '../../helpers/index';

import moment from "moment";
import AmCharts from "@amcharts/amcharts3-react";
import { authenticationService } from "../../services/authentication-service";


const currentUser = authenticationService.currentUserValue
//console.log("currentUser", currentUser)

const {RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
//const monthFormat = 'YYYY/MM';

//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;




class TendancesVentes extends React.Component {
    state = {
        tendancesVentes: [],
        startDate: firstDay,
        endDate: lastDay,
        codAgence: '',
        formLayout: 'horizontal',
        agenceCheckedList: [],
        indeterminate: true,
        checkAll: true,
        defaultCkeckAgence: []


    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const startDate = moment(new Date(firstDay), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(lastDay), 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getAllAgences()
            .then(agences => {
             //   console.log('agences', agences)
                let defaultCkeckAgence = agences && agences.length ?
                    agences.map((details, k) => {
                        return details.codAgence
                    }) : []
                this.setState({ agences: agences, defaultCkeckAgence: defaultCkeckAgence })

                let req = {
                    startDate:startDate,
                    endDate: endDate,
                    codeAgence: defaultCkeckAgence,
        
                }
        
                services.getTendanceVente(req)
                    .then(tendancesVentes => {
                        //console.log('tendancesVentes & REQ',req, tendancesVentes)
                        this.setState({ tendancesVentes })
                    })
            })
    }



    //SELECTION AGENCE LORS CREATION OU MISE A JOUR USER
    handleChangeAgence = (value) => {
        this.setState({ codAgence: value })
    }


    //FILTRE POUR TABLEAU UTILISATEUR PAR AGENCE
    /*handleChangeAgence2 = (value) => {
        this.setState({ codAgenceChange: value })

        const startDate = moment(new Date(this.state.startDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(this.state.endDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        
        services.getTendanceVente(startDate, endDate, value)
            .then(tendancesVentes => {
                console.log('tendancesVentes2', tendancesVentes)
                this.setState({ tendancesVentes: tendancesVentes })
            })
    }*/

    onDateChange = (date, dateString) => {
     //    console.log("DATE", date, dateString, dateString[0]);

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
       // console.log("endDate", endDate)

        let req = {
            startDate:startDate,
            endDate: endDate,
            codeAgence: this.state.defaultCkeckAgence,

        }
        //console.log("REQ", req)

        services.getTendanceVente(req)
            .then(tendancesVentes => {
                //console.log('tendancesVentes2', tendancesVentes)
                this.setState({ tendancesVentes: tendancesVentes, startDate: dateString[0], endDate: dateString[1], })
            })
    }

    onCheckAgenceChange = (agence) => {
       // console.log("AGENCE CHEK", agence,)
        //this.setState({ defaultCkeckAgence: agence })
        //let codeAgence = agence

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

        let req = {
            startDate:startDate,
            endDate: endDate,
            codeAgence: agence,

        }
        services.getTendanceVente(req)
            .then(tendancesVentes => {
               // console.log('tendancesVentes2', tendancesVentes)
                this.setState({ tendancesVentes: tendancesVentes, defaultCkeckAgence: agence })
            })
    }


    render() {
        const {
            agences,

            codAgence,
            codAgenceChange,
            tendancesVentes,
            startDate,
            endDate,
            formLayout,
            agenceCheckedList,
            checkAll,
            defaultCkeckAgence,

        } = this.state

        /* const formItemLayout = formLayout === 'horizontal' ? {
             labelCol: { xs: 24, sm: 6 },
             wrapperCol: { xs: 24, sm: 14 },
         } : null;*/

        const Option = Select.Option;

        let data = tendancesVentes && tendancesVentes.length ?
            tendancesVentes.map((details, k) => {
                return {
                    "date": moment(new Date(details.date), 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    "montant": details.total
                } //details && details.article ? details.article.codPro : ''
            }) : []
        //console.log("DETAILS", data)

        /* let sortedData = data.sort(
             (p1, p2) => (p1.chiffre < p2.chiffre) ? 1 : (p1.chiffre > p2.chiffre) ? -1 : 0);
         // console.log("sortedData", sortedData)*/

        let totalChiffre = data && data.length ?
            data.reduce(function (prev, cur) {
                return prev + parseInt(cur.montant)
            }, 0) : 0
        //console.log("totalChiffre", totalChiffre)


        //console.log("defaultCkeckAgence", defaultCkeckAgence)


        const config = {
            "type": "serial",
            "theme": "light",
            "marginRight": 40,
            "marginLeft": 40,
            "autoMarginOffset": 20,
            "mouseWheelZoomEnabled": true,
            "dataDateFormat": "DD-MM-YYYY",
            "valueAxes": [{
                "id": "v1",
                "axisAlpha": 0,
                "position": "left",
                "ignoreAxisWidth": true
            }],
            "balloon": {
                "borderThickness": 1,
                "shadowAlpha": 0
            },
            "graphs": [{
                "id": "g1",
                /*"balloon": {
                    "drop": true,
                    "adjustBorderColor": false,
                    "color": "#ffffff"
                },*/
                "bullet": "round",
                "bulletBorderAlpha": 1,
                "bulletColor": "#FFFFFF",
                "bulletSize": 5,
                "hideBulletsCount": 50,
                "lineThickness": 2,
                "title": "red line",
                "useLineColorForBulletBorder": true,
                "valueField": "montant",
                "balloonText": `<span style='font-size:18px;'>[[date]]: [[montant]] F</span>`
            }],
            "chartScrollbar": {
                "graph": "g1",
                "oppositeAxis": false,
                "offset": 30,
                "scrollbarHeight": 80,
                "backgroundAlpha": 0,
                "selectedBackgroundAlpha": 0.1,
                "selectedBackgroundColor": "#888888",
                "graphFillAlpha": 0,
                "graphLineAlpha": 0.5,
                "selectedGraphFillAlpha": 0,
                "selectedGraphLineAlpha": 1,
                "autoGridCount": true,
                "color": "#AAAAAA"
            },
            "chartCursor": {
                "pan": true,
                "valueLineEnabled": true,
                "valueLineBalloonEnabled": true,
                "cursorAlpha": 1,
                "cursorColor": "#258cbb",
                "limitToGraph": "g1",
                "valueLineAlpha": 0.2,
                "valueZoomable": true
            },
            "valueScrollbar": {
                "oppositeAxis": false,
                "offset": 50,
                "scrollbarHeight": 10
            },
            "categoryField": "date",
            "categoryAxis": {
                "parseDates": true,
                "dashLength": 1,
                "minorGridEnabled": true
            },
            "export": {
                "enabled": false
            },
            "dataProvider": data
        };


        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?
                    <Card title="Tendances Ventes">

                        <Media query="(min-width: 600px)" render={() =>
                        (

                            <div>
                                <Row >
                                    <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                    </Col>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black", fontWeight: "bold" }}><span>Filtrer par Période</span></h6>
                                        </FormGroup>

                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <Card className="gx-card" title="Agences" >
                                            <div className="gx-border-bottom gx-pb-3 gx-mb-3">

                                                <Checkbox.Group value={defaultCkeckAgence}  /*defaultValue={defaultCkeckAgence}*/ style={{ width: '100%' }} onChange={this.onCheckAgenceChange}>

                                                    {
                                                        agences && agences.length ?
                                                            agences.map((agence, k) => {
                                                                return <div>
                                                                    <Checkbox
                                                                        value={agence.codAgence}

                                                                        className="gx-mb-3"
                                                                        //onChange={this.onCheckAgenceChange}
                                                                        defaultChecked
                                                                        //checked={checkAll}
                                                                        //disabled={true}
                                                                        key={k}
                                                                        id={k}
                                                                    >
                                                                        {agence.nomAgence}
                                                                    </Checkbox><br />
                                                                </div>
                                                            }) : null
                                                    }

                                                </Checkbox.Group>


                                            </div>
                                            <h3 style={{ color: "red" }}>Total CA {`${numberWithCommas(totalChiffre)} F CFA`}</h3>

                                            {/*<CheckboxGroup options={plainOptions} value={this.state.agenceCheckedList} onChange={this.onChange} />*/}
                                        </Card>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>

                                </Row>



                            </div>


                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (


                            <div>
                                <Row >
                                 

                                    <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black", fontWeight: "bold" }}><span>Filtrer par Période</span></h6>
                                        </FormGroup>

                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <Card className="gx-card" title="Agences" >
                                            <div className="gx-border-bottom gx-pb-3 gx-mb-3">

                                                <Checkbox.Group value={defaultCkeckAgence}  /*defaultValue={defaultCkeckAgence}*/ style={{ width: '100%' }} onChange={this.onCheckAgenceChange}>

                                                    {
                                                        agences && agences.length ?
                                                            agences.map((agence, k) => {
                                                                return <div>
                                                                    <Checkbox
                                                                        value={agence.codAgence}

                                                                        className="gx-mb-3"
                                                                        //onChange={this.onCheckAgenceChange}
                                                                        defaultChecked
                                                                        //checked={checkAll}
                                                                        //disabled={true}
                                                                        key={k}
                                                                        id={k}
                                                                    >
                                                                        {agence.nomAgence}
                                                                    </Checkbox><br />
                                                                </div>
                                                            }) : null
                                                    }

                                                </Checkbox.Group>


                                            </div>
                                            <h3 style={{ color: "red" }}>Total CA {`${numberWithCommas(totalChiffre)} F CFA`}</h3>

                                            {/*<CheckboxGroup options={plainOptions} value={this.state.agenceCheckedList} onChange={this.onChange} />*/}
                                        </Card>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>

                                </Row>



                            </div>


                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }



            </div>
        )
    }
}


export default TendancesVentes;
