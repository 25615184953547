import React from "react";
import { Card, Form, Input, Divider, Icon, Button, Modal, Select, message, Popconfirm, InputNumber, DatePicker, Radio } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, ListGroup, ListGroupItem, Table, Spinner } from 'reactstrap';
import Media from 'react-media';

import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import 'moment/locale/fr-ca';
import locale from 'antd/es/date-picker/locale/fr_FR';

import { services } from '../../services/services';
import { authenticationService } from "../../services/authentication-service";
//import { use_logiciel } from '../../../env';

import { numberWithCommas } from '../../helpers/index';



const currentUser = authenticationService.currentUserValue

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const confirm = Modal.confirm;

const FormItem = Form.Item;

const moment = require('moment')

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker } = DatePicker;



class FactureClient extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isListArticleVisible: false,
        isDetailsArticleVisible: false,
        isValidationValidation: false,
        repartitions: [],
        listeArticlesSelected: [],
        articles: [],
        articlesCodPro: [],
        formLayout: 'horizontal',

        usuel: "1",
        codTyp: "1",
        libCat: '',
        codeCat: '',
        emailFour: '',
        refFactFour: '',
        codAgence: '',
        modRegl: '',
        codmod: '',
        tauxAib: 0,
        fraisTransport: 0,
        cleRepartition: 0,
        dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        startDate: moment(),
        endDate: moment(),
        refFact: '',
        etatCmde: 'EN COURS',
        puRemise: 0,
        autreFrais: 0,
        montantRemise: 0,
        puRemise: 0,
        qtCommander: 0,

        factures: [],
        clients: [],
        //agences: [],
        gender: 'M',
        raisonSociale: '',
        codClient: '',
        mentionClient: 'NOUVEAU CLIENT',
        typeClient: 'CLIENT ORDINAIRE',
        usuel: '1',
        dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        ifu: '',
        observation: '',
        numeroCarte: '',

        waitingEndMsg: '',
        isLoading: true,
        agences: []
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        const startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')


        this.setState({factures:[], isLoading: false, waitingEndMsg: `Veuillez sélectionner un Client pour voir les factures!` })

        /*services.getFactures('', '', startDate, endDate/*this.state.dateEnCours/)
            .then(factures => {
                //console.log('factures', factures)
                this.setState({ factures })

                setTimeout(() => {

                    // console.log("real articles", factures)
                    if (!factures || !Array.isArray(factures)
                        || !factures.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Veuillez sélectionner un Client pour voir les factures!` })
                    }
                }, 500)
            })*/
        services.getClients()
            .then(clients => {
                //  console.log('clients', clients)
                this.setState({ clients })
            })

        /*services.getStocksAgence()
            .then(articles => {
                //  console.log('articles', articles)
                this.setState({ articles })
            })*/

        services.getAllAgences()
            .then(agences => {
                //  console.log('agences', agences)
                this.setState({ agences })
            })
    }

    handleChangeAgence = (value) => {

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.setState({ 
            codAgenceChange: value, 
            codAgence: value, 
            isLoading: true, 
            waitingEndMsg: '', 
            factures: []
         })
        
        //  console.log("AGENCE CHANGE", this.state.dateEnCours,value, startDate, endDate)
        services.getFacturesClients(this.state.codClient, value, startDate, endDate)
            .then(factures => {
                //  console.log('factures', factures)
                this.setState({ factures: factures, isLoading: false, })

                setTimeout(() => {

                    if (!factures || !Array.isArray(factures)
                        || !factures.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: (this.state.codClient== '')? `Veuillez sélectionner un client pour voir ses factures pour cette Agence` :`Vous n\'avez aucune Facture actuellement pour ce client` })
                    }
                }, 500)
            })

    }


    handleAddOpen = () => {
        this.setState({
            dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            codClient: '',
            observation: '',
            modRegl: 'Cash',
            codmod: 'ESPECES',
            montantRemise: 0,
            fraisTransport: 0,
            autreFrais: 0,
            tauxAib: 0,
            totalTva: 0,
            netHT: 0,
            tauxRemise: 0,
            netCommercial: 0,
            montantTtc: 0,
            montantAib: 0,
            netaPayer: 0,
            montFact: 0,
            refFact: '',
            isAddModalVisible: !this.state.isAddModalVisible,
            articlesCodPro: [],
            listeArticlesSelected: [],
        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codCat: '',

            libCat: '',

        })
    }


    onDateChange = (date, dateString) => {
        // console.log("DATE", date, dateString, dateString[0]);
        this.setState({ isLoading: true, factures: [] })

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //  console.log("DATE CHANGE PERIODE", dateString[0], dateString[1], this.state.dateEnCours, this.state.codAgence, startDate, endDate)

        services.getFacturesClients(this.state.codClient, this.state.codAgence, startDate, endDate)
            .then(factures => {
                //  console.log('factures', factures)
                this.setState({ factures: factures,  })

                setTimeout(() => {

                    if (!factures || !Array.isArray(factures)
                        || !factures.length > 0) {
                        this.setState({ 
                            isLoading: false, 
                            waitingEndMsg: (this.state.codClient== '')? `Veuillez sélectionner un client pour voir les factures pour la période sélectionnée` :`Vous n\'avez aucune Facture actuellement pour ce client`
                         })
                    }
                }, 500)
            })
        this.setState({ startDate: dateString[0], endDate: dateString[1] })

    }



    handleView = (row) => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            facture: row,

            numFact: row.numFact,
            dateFact: row.dateFact,
            codClient: row.codClient,
            observation: row.observation,
            modRegl: row.modRegl,
            codmod: row.codmod,
            montantRemise: row.montantRemise,
            fraisTransport: row.fraisTransport,
            autreFrais: row.autreFrais,
            tauxAib: row.tauxAib,
            totalTva: row.totalTva,
            netHT: row.netHT,
            tauxRemise: row.tauxRemise,
            netCommercial: row.netCommercial,
            montantTtc: row.montantTtc,
            montantAib: row.montantAib,
            netaPayer: row.netaPayer,
            montFact: row.montFact,
            refFact: row.refFact,
            nomAgence: row && row.agences ? row.agences.nomAgence : '',
            nomAgent: `${row && row.userInfo ? row.userInfo.prenomAgent : ''} ${row && row.userInfo ? row.userInfo.nomAgent : ''}`,

            //codAgence: `${row.codAgence}`,
            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        //RESTE CETTE PARTIE AVEC LES AGENTS
                        //numFact: facture.id,

                        puRemise: details.puRemise,
                        qtCommander: details.qtCommander,
                        codTva: details.codTva,
                        tauxTva: details.tauxTva,
                        puAchat: details.puAchat,
                        puBrut: details.puBrut,
                        puRemise: details.puRemise,
                        puUtilise: details.puUtilise,
                        codAgence: details.codAgence,
                        //codProd: details.codProd,

                        //qtEntree: details.qtEntree,
                        //qteStock: details && details.stock ? details.stock.qteStock : '',
                        //qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            dateRepartition: '',
            observation: '',
            codAgence: '',

            listeArticlesSelected: [],
        });
    }


    onRechercheArticleChange = (e) => {
        services.getStocksAgence(e.target.value.toUpperCase())
            .then(articles => {
                //  console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod == x.codProd)),
                })
            })
    }



    openDetailArticleModal = (article) => {
        this.setState({
            codTva: article.article ? article.article.codTvaVente : '',
            //tauxTva,
            puAchat: article.article ? article.article.puAchat : 0,
            puBrut: parseInt(article.article ? article.article.puVente : 0),

            designation: article ? article.article.designation : '',
            article: article,
            qteStock: article.qteStock,
            qteStockEnCoursValidation: article.qteStockEnCoursValidation,
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            qtCommander: 0,
            puRemise: 0,
            //codTva: '',
        })
    }


    closeDetailArticleModal = () => {
        this.setState({ isDetailsArticleVisible: false })
    }


    handleChangeClient = (value) => {
        
        this.setState({ isLoading: true, factures: [], codClient: value, waitingEndMsg: "" })

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getFacturesClients(value, this.state.codAgence, startDate, endDate)
            .then(factures => {
               //   console.log('factures', factures)
                this.setState({ factures: factures, isLoading: false, })

                setTimeout(() => {

                    if (!factures || !Array.isArray(factures)
                        || !factures.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: (this.state.codClient== '')? `Veuillez sélectionner un client pour voir ses factures` :`Vous n\'avez aucune Facture actuellement pour ce client` })
                    }
                }, 500)
            })
    }



    render() {
        const {
            isModalVisible,
            loading,

            codAgenceChange,
           
            dateFact,
            observation,
            codAgence,
            modRegl,
            // montantAp,
            codmod,
            refFact,
         
            agences,


            fraisTransport,
            listeArticlesSelected,

            codClient,
            
            clients,

            factures,

            qteStock,

            formLayout,

            tauxAib,
            //montantAib,
            //montantTtc,
            //netCommercial,
            //tauxRemise,
            montantRemise,
            autreFrais, //n'existe pas

            nomAgence,
            nomAgent,

            designation,

            isLoading,
            waitingEndMsg,

            startDate,
            endDate,
            facture,

        } = this.state

        ////  console.log('listeArticlesSelected', listeArticlesSelected)


        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0


        const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.puUtilise * cur.qtCommander)
            }, 0) : 0

        const tauxRemise = ((100 * parseInt(montantRemise) / netHT))

        const netCommercial = (parseInt(netHT) - parseInt(montantRemise))

        const montantTtc = (parseInt(netCommercial) + parseInt(totalTva) + parseInt(fraisTransport) + parseInt(autreFrais))

        const montantAib = ((tauxAib * montantTtc) / 100)


        const netaPayer = parseInt(montantTtc) + parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

        const montantAp = netaPayer;



        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;


        const columnsFactures = [{
            dataField: 'numFact',
            text: 'N°',
            headerAttrs: { width: 50 },
        },
        /*{
            dataField: 'refFact',
            text: 'Référence'
        },*/
        {
            dataField: 'dateFact',
            text: 'Date',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                             moment(row.dateFact, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        }
                    </div>
                );
            },
        },
        {
            dataField: 'clients.raisonSociale',
            text: 'Clients'
        },
        {
            dataField: 'userInfo',
            text: 'Agents',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            row.userInfo ? `${row.userInfo.prenomAgent} ${row.userInfo.nomAgent}` : ''
                        }
                    </div>
                );
            },
        },
        {
            dataField: 'agences.nomAgence',
            text: 'Agence'
        },

        {
            dataField: 'montFact',
            text: 'Montant Total',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            numberWithCommas(parseInt(row.montFact))
                        }
                    </div>
                );
            },
        },

        {
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                            <Button
                                title="Visualiser"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Button
                                title="Modifier"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Button
                                title="Valider"
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                className="gx-btn-block gx-btn-yellow"
                                size="sm"
                                onClick={() => this.handleValider(row)}
                            >
                                <i className="icon icon-check-circle-o" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Répartition??" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    title="Suppprimer"
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                    </Button>

                            </Popconfirm>
                    */}

                    </div>
                );
            },

        }

        ];


        const columnsFacturesPhone = [


            /*{
                dataField: 'refFact',
                text: 'Référence'
            },*/

            {
                dataField: 'clients.raisonSociale',
                text: 'Clients'
            },
            {
                dataField: 'montFact',
                text: 'Montant Total',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                numberWithCommas(parseInt(row.montFact))
                            }
                        </div>
                    );
                },
            },


            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                                <Button
                                    title="Modifier"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleUpdateOpen(row)}
                                >
                                    <i className="icon icon-edit" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&* row.etatCmde === 'EN COURS' &&
                                <Button
                                    title="Valider"
                                    //type="danger"
                                    //fullWidth
                                    variant="contained"
                                    //color="primary"
                                    size="sm"
                                    className="gx-btn-block gx-btn-yellow"
                                    onClick={() => this.handleValider(row)}
                                >
                                    <i className="icon icon-check-circle-o" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&* row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="sm"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                        */ }

                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'numFact',
            order: 'desc'
        }];

        let totalChiffre = factures && factures.length ?
            factures.reduce(function (prev, cur) {
                return prev + parseInt(cur.montFact)
            }, 0) : 0


        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?
                <Card title="Liste des Factures">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={factures}
                            columns={columnsFactures}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={4} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                {/*<SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />*/}
                                                <InputGroup compact>

                                                    <Select
                                                        value={codClient}
                                                        showSearch
                                                        //style={{width: 200}}
                                                        style={{ width: '85%' }}
                                                        placeholder="Selectionner un Client"
                                                        optionFilterProp="children"
                                                        onChange={this.handleChangeClient}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        {
                                                            clients && clients.length ?
                                                                clients.map((client, k) => {
                                                                    return <Option value={client.codClient}>{client.raisonSociale}</Option>
                                                                }) : null
                                                        }

                                                    </Select>
                                                    <Button disabled onClick={this.handleCancelAddClient} style={{ width: '15%' }} type="primary" ><i className="icon icon-search" /> </Button>
                                                </InputGroup>
                                            </Col>

                                            <Col md={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <FormGroup className="mr-2">
                                                    <RangePicker className="gx-w-100"
                                                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                        format={dateFormat}
                                                        onChange={this.onDateChange}
                                                        ranges={{
                                                            "Aujourd'hui": [moment(), moment()],
                                                            'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                            'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                            'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                        }}
                                                    />
                                                    <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                </FormGroup>
                                     

                                                <FormGroup className="mr-2">
                                                    <Select

                                                        value={codAgenceChange}
                                                        showSearch
                                                        //style={{width: 200}}
                                                        style={{ width: '120%' }}
                                                        placeholder="Filtrer par Agence"
                                                        optionFilterProp="children"
                                                        onChange={this.handleChangeAgence}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        <Option value=''>TOUTES</Option>
                                                        {
                                                            agences && agences.length ?
                                                                agences.map((agence, k) => {
                                                                    return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                }) : null
                                                        }

                                                    </Select>
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                </FormGroup>

                                        
                                            </Col>


                                        </Row>
                                        {factures &&
                                            Array.isArray(factures) &&
                                            factures.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <h3 style={{ color: "red", fontWeight: "bold" }}><u>Total Vente</u>: {(totalChiffre === 1) ? 0 : `${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                </Col>
                                            </Row>
                                        }


                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            factures &&
                                                Array.isArray(factures) &&
                                                factures.length > 0 ?
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={factures}
                            columns={columnsFacturesPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={24} sm={24} className="ml-3" >

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ width: "auto", minWidth: "148%", maxWidth: "100%", display: 'flex' }}
                                                    //style={{ maxWidth: 450, width: 'auto', display: 'flex' }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                         
                                            <Col xm={12} md={12} >
                                                <FormGroup >
                                                    <RangePicker className="gx-w-100"
                                                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                        format={dateFormat}
                                                        onChange={this.onDateChange}
                                                        ranges={{
                                                            "Aujourd'hui": [moment(), moment()],
                                                            'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                            'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                            'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                        }}
                                                    />
                                                    <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                </FormGroup>

                                          
                                            </Col>
                                            <Col xm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>
                                                <FormGroup>
                                                    <Select

                                                        value={codAgenceChange}
                                                        showSearch
                                                        //style={{width: 200}}
                                                        style={{ width: "auto", minWidth: "265%", maxWidth: "100%" }}
                                                        placeholder="Filtrer par Agence"
                                                        optionFilterProp="children"
                                                        onChange={this.handleChangeAgence}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        <Option value=''>TOUTES</Option>
                                                        {
                                                            agences && agences.length ?
                                                                agences.map((agence, k) => {
                                                                    return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                }) : null
                                                        }

                                                    </Select>
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                </FormGroup>
                                            </Col>
                                           

                                        </Row>
                                        {factures &&
                                            Array.isArray(factures) &&
                                            factures.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <h4 style={{ color: "red", fontWeight: "bold" }}><u>Total Vente</u>: {(totalChiffre === 1) ? 0 : `${numberWithCommas(totalChiffre)} F CFA`}</h4>
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {factures &&
                                            Array.isArray(factures) &&
                                            factures.length > 0 ?
                                            <BootstrapTable
                                                {...props.baseProps}
                                                //bootstrap4
                                                wrapperClasses="table-responsive"
                                                //selectRow={ selectRow }
                                                //defaultSorted={defaultSorted}
                                                pagination={paginationFactory()}
                                                defaultSorted={defaultSorted}
                                                striped
                                                hover
                                                condensed
                                                headerWrapperClasses="foo"
                                                noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                            //filter={filterFactory()}
                                            />
                                            :
                                            isLoading ?
                                                <div style={{ justifyContent: 'center' }}>

                                                    <Row style={{ justifyContent: 'center' }}>
                                                        <Col style={{ justifyContent: 'center' }}>
                                                            <Spinner type="grow" color="primary" />
                                                            <Spinner type="grow" color="secondary" />
                                                            <Spinner type="grow" color="success" />
                                                            <Spinner type="grow" color="danger" />
                                                            <Spinner type="grow" color="warning" />
                                                            <Spinner type="grow" color="info" />
                                                            <Spinner type="grow" color="light" />
                                                            <Spinner type="grow" color="dark" />
                                                        </Col>
                                                    </Row> <br />
                                                </div>
                                                : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>
                :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }


                

                <Modal
                    width={900}
                    visible={isModalVisible}
                    title="Détails Facture"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nomAgent">Agent</Label>
                                    <Input disabled type="text" name="nomAgent" value={nomAgent} onChange={this.handleChange} id="nomAgent" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nomAgence">Agence</Label>
                                    <Input disabled type="text" name="nomAgence" id="nomAgence" value={nomAgence} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFact">Référence Devis</Label>
                                    <Input disabled type="text" name="refFact" value={refFact} onChange={this.handleChange} id="refFact" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateFact">Date</Label>
                                    <Input disabled type="text" name="dateFact" id="dateFact" value={moment(dateFact, 'YYYY-MM-DD').format('DD/MM/YYYY') } onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codClient">Client</Label>
                                    <InputGroup compact>

                                        <Select
                                            disabled
                                            value={codClient}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '100%' }}
                                            placeholder="Selectionner un Client"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeClient}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                clients && clients.length ?
                                                    clients.map((client, k) => {
                                                        return <Option value={client.codClient}>{client.raisonSociale}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        {/*<Button disabled onClick={this.handleCancelAddClient} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>*/}
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input disabled type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/*<Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFactFour">Facture Fournisseur N°</Label>
                                    <Input type="text" name="refFactFour" value={refFactFour} onChange={this.handleChange} id="refFactFour" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>*/}

                        <Row form>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="modRegl">Type règlement</Label>
                                    <Select disabled className="gx-mr-3 gx-mb-3" value={modRegl} onChange={this.handleChangeTypeReglement}>
                                        <Option value="Cash">Cash</Option>
                                        <Option value="Partiel">Partiel</Option>
                                        <Option value="Crédit">Crédit</Option>

                                    </Select>
                                    {//(modRegl === "A" || modRegl === "C" || modRegl === "E" || modRegl === "F") &&
                                        //<h6 style={{ color: "black" }}><span>NB:Si la commande n'est pas payée à  l'avance, veuillez stipuler pour le Type de Règlement Crédit</span></h6>
                                    }

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codmod">Mode règlement</Label>
                                    <Select disabled className="gx-mr-3 gx-mb-3" value={codmod} onChange={this.handleChangeModReglem}>
                                        <Option value="AUTRE">Autre</Option>
                                        <Option value="CARTE">Carte Bancaire</Option>
                                        <Option value="CHEQUE">Chèque</Option>
                                        <Option value="ESPECES">Espèces</Option>
                                        <Option value="MOMO">Mobile Money</Option>
                                        <Option value="VIREMENT">Virement</Option>
                                    </Select>

                                </FormGroup>
                            </Col>

                        </Row>


                        <Row>
                            <Col md={8}>
                                <button hidden disabled className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            {/*<th className="text-center">PU Brut</th>
                                            <th className="text-center">Montant Remise</th>
                                <th className="text-center">PU Net HT</th>*/}
                                            <th className="text-center">Qté Commandée</th>
                                            {/*<th className="text-center">Montant</th>*/}

                                            <th className="text-center">Tva</th>
                                            {/*<th className="text-center">Retirer</th>*/}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseInt(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            {/*<td className="text-center">{numberWithCommas(parseInt(article.puBrut))}</td>
                                                            <td className="text-center">{numberWithCommas(parseInt(article.puRemise))}</td>
                                                            <td className="text-center">{numberWithCommas(parseInt(article.puUtilise))}</td>*/}
                                                            <td className="text-center">{parseInt(article.qtCommander)}</td>
                                                            {/*<td className="text-center">{numberWithCommas(parseInt(article.puUtilise) * parseInt(article.qtCommander))}</td>*/}

                                                            <td className="text-center">{article.tauxTva}</td>

                                                            {/*<Button disabled className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>*/}

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            {/*<Col md={5}>
                                <Card title="Groupe de Taxation et TVA" className="gx-card ">
                                    <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                {/**<th className="text-center"> </th> /}

                                                <th className="text-center">Base</th>
                                                <th className="text-center">Taux(%)</th>
                                                <th className="text-center">Montant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                    listeArticlesSelected.map((article, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> /}

                                                                <td className="text-center">{numberWithCommas(parseInt(article.puUtilise) * parseInt(article.qtCommander))}</td>

                                                                <td className="text-center">{article.tauxTva}</td>

                                                                <td className="text-center">{numberWithCommas((parseInt(article.puUtilise) * parseInt(article.qtCommander) * parseInt(article.tauxTva)) / 100)}</td>



                                                            </tr>
                                                        );
                                                    })
                                                    : null
                                            }

                                        </tbody>
                                    </Table>
                                    <FormItem className="mt-3" label="Total TVA"  {...formItemLayout}>
                                        <Input disabled type="number" name="totalTva" value={totalTva} id="totalTva" onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>

                                        </Col>*/}
                            <Col md={12}>
                                <Card title="Totaux et Taxes" className="gx-card">
                                    <FormItem label="Net HT"  {...formItemLayout}>

                                        <Input disabled type="number" name="netHT" id="netHT" value={facture && facture.netHT || 0} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    {/*<FormItem label="Montant Remise"  {...formItemLayout}>

                                        <Input disabled type="number" name="montantRemise" id="montantRemise" value={montantRemise} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Taux Remise"  {...formItemLayout}>

                                        <Input disabled type="number" name="tauxRemise" id="tauxRemise" value={tauxRemise} onChange={this.handleChange} placeholder="" />
                                        </FormItem>*/}

                                    <FormItem label="Net Commercial"  {...formItemLayout}>

                                        <Input disabled type="number" name="netCommercial" id="netCommercial" value={facture && facture.netCommercial} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Total TVA"  {...formItemLayout}>

                                        <Input disabled type="number" name="totalTva" value={facture && facture.totalTva} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    {/*<FormItem label="Frais Transport"  {...formItemLayout}>

                                        <Input disabled type="number" name="fraisTransport" id="fraisTransport" value={fraisTransport} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Autres Frais"  {...formItemLayout}>

                                        <Input disabled type="number" name="refFact" id="autreFrais" value={autreFrais} onChange={this.handleChange} placeholder="" />
                                    </FormItem>*/}

                                    <FormItem label="Montant TTC"  {...formItemLayout}>

                                        <Input disabled type="number" name="montantTtc" id="montantTtc" value={facture && facture.montantTtc} onChange={this.handleChange} placeholder="" />
                                    </FormItem>


                                    <FormItem label="Net à Payer"  {...formItemLayout}>

                                        <Input disabled type="number" name="netaPayer" id="netaPayer" value={facture && facture.netaPayer} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>


                            </Col>
                        </Row>

                    </Form>
                </Modal>

                

                


            </div>
        )
    }
}


export default FactureClient;
