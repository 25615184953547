import React from "react";
import { Card, Form, Input, Divider, Icon, Button, Modal, Select, message, Popconfirm, InputNumber, DatePicker, Radio } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, ListGroup, ListGroupItem, Table, Spinner } from 'reactstrap';
import Media from 'react-media';

import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import 'moment/locale/fr-ca';
import locale from 'antd/es/date-picker/locale/fr_FR';

import { services } from '../../services/services';
import { authenticationService } from "../../services/authentication-service";

import { numberWithCommas } from '../../helpers/index';



const currentUser = authenticationService.currentUserValue

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const confirm = Modal.confirm;

const FormItem = Form.Item;

const moment = require('moment')

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker } = DatePicker;



class ArticlesVendus extends React.Component {
    state = {

        formLayout: 'horizontal',

        codAgence: '',

        dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        startDate: moment(),
        endDate: moment(),

        articles: [],


        waitingEndMsg: '',
        isLoading: true,
        agences: []
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        const startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        //const dateEnCours= moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
        //console.log('Date', this.state.dateEnCours)
        services.getArticlesVendus(startDate, endDate, '')
            .then(articles => {
                //console.log('ARTICLE VENDUS', articles)
                this.setState({ articles })

                setTimeout(() => {

                    // console.log("real articles", articles)
                    if (!articles || !Array.isArray(articles)
                        || !articles.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucune Facture pour cette période!` })
                    }
                }, 500)
            })

        services.getAllAgences()
            .then(agences => {
                //  console.log('agences', agences)
                this.setState({ agences })
            })
    }

    handleChangeAgence = (value) => {

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.setState({ codAgenceChange: value })
        this.setState({ isLoading: true, waitingEndMsg: '', articles: [] })
        //  console.log("AGENCE CHANGE", this.state.dateEnCours,value, startDate, endDate)

        services.getArticlesVendus(startDate, endDate, value)
            .then(articles => {
               // console.log('ARTICLE VENDUS', articles)
                this.setState({ articles: articles, codAgence: value, isLoading: false, })

                setTimeout(() => {

                    // console.log("real articles", articles)
                    if (!articles || !Array.isArray(articles)
                        || !articles.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucun article vendue pour cette agence!` })
                    }
                }, 500)
            })

    }




    onDateChange = (date, dateString) => {
        // console.log("DATE", date, dateString, dateString[0]);
        this.setState({ isLoading: true, articles: [], waitingEndMsg:'' })

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //  console.log("DATE CHANGE PERIODE", dateString[0], dateString[1], this.state.dateEnCours, this.state.codAgence, startDate, endDate)

        services.getArticlesVendus(startDate, endDate, this.state.codAgence)
        .then(articles => {
          //  console.log('ARTICLE VENDUS', articles)
            this.setState({ articles: articles, isLoading: false, })

            setTimeout(() => {

                // console.log("real articles", articles)
                if (!articles || !Array.isArray(articles)
                    || !articles.length > 0) {
                    this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucun article vendue pour cette période!` })
                }
            }, 500)
        })
        this.setState({ startDate: dateString[0], endDate: dateString[1] })


    }






    render() {
        const {

            codAgenceChange,

            agences,


            articles,

            qteStock,

            formLayout,



            isLoading,
            waitingEndMsg,

            startDate,
            endDate,

        } = this.state


        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;


        const columnsArticles = [{
            dataField: 'codPro',
            text: 'Code',
            headerAttrs: { width: 50 },
        },
        {
            dataField: 'designation',
            text: 'Désignation'
        },

        {
            dataField: 'qteTotal',
            text: 'Qtantité Vendue',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            numberWithCommas(parseInt(row.qteTotal))
                        }
                    </div>
                );
            },
        },


        {
            dataField: 'montTotal',
            text: 'Montant',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            numberWithCommas(parseInt(row.montTotal))
                        }
                    </div>
                );
            },
        },

        /*{
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                            <Button
                                title="Visualiser"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                        }


                    </div>
                );
            },

        }*/

        ];


        const columnsArticlesPhone = [


            {
                dataField: 'designation',
                text: 'Désignation'
            },

            {
                dataField: 'qteTotal',
                text: 'Qté Vendue',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                numberWithCommas(parseInt(row.qteTotal))
                            }
                        </div>
                    );
                },
            },


           /* {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }


                        </div>
                    );
                },

            }*/

        ];

        const defaultSorted = [{
            dataField: 'qteTotal',
            order: 'desc'
        }];

        let totalChiffre = articles && articles.length ?
            articles.reduce(function (prev, cur) {
                return prev + parseInt(cur.montTotal)
            }, 0) : 0

            let totalQte = articles && articles.length ?
            articles.reduce(function (prev, cur) {
                return prev + parseInt(cur.qteTotal)
            }, 0) : 0

            let totalArticle = articles && articles.length 
          
            let sortedData = articles.sort(
                (p1, p2) => (p1.qteTotal < p2.qteTotal) ? 1 : (p1.qteTotal > p2.qteTotal) ? -1 : 0);


        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?
                    <Card title="Liste des Articles Vendus">

                        <Media query="(min-width: 600px)" render={() =>
                        (
                            <ToolkitProvider
                                keyField="id"
                                data={articles}
                                columns={columnsArticles}
                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={3} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>

                                                <Col md={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                    <FormGroup className="mr-2">
                                                        <RangePicker className="gx-w-100"
                                                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                            format={dateFormat}
                                                            onChange={this.onDateChange}
                                                            ranges={{
                                                                "Aujourd'hui": [moment(), moment()],
                                                                'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                                'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                                'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                            }}
                                                        />
                                                        <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                    </FormGroup>


                                                    <FormGroup className="mr-4">
                                                        <Select

                                                            value={codAgenceChange}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: '120%' }}
                                                            placeholder="Filtrer par Agence"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeAgence}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value=''>TOUTES</Option>
                                                            {
                                                                agences && agences.length ?
                                                                    agences.map((agence, k) => {
                                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                    }) : null
                                                            }

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                    </FormGroup>

                                                </Col>


                                            </Row>
                                            {articles &&
                                                Array.isArray(articles) &&
                                                articles.length > 0 &&
                                                <Row style={{display:"flex", justifyContent:"space-around",}}>
                                                    <Col md={4} sm={4} xm={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>
                                                        <h3 style={{ color: "green", fontWeight: "bold" }}><u>Nombre Articles</u>: {`${numberWithCommas(totalArticle)}`}</h3>
                                                    </Col>
                                                    <Col md={4} sm={4} xm={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-center', alignItems: 'baseline', }}>
                                                        <h3 style={{ color: "blue", fontWeight: "bold" }}><u>Total Quantié Vendue</u>: {`${numberWithCommas(totalQte)}`}</h3>
                                                    </Col>
                                                    <Col md={4} sm={4} xm={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                        <h3 style={{ color:"red", fontWeight: "bold" }}><u>Montant</u>: { `${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                    </Col>
                                                </Row>
                                            }


                                            {
                                                !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                            }
                                            {
                                                articles &&
                                                    Array.isArray(articles) &&
                                                    articles.length > 0 ?
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        //bootstrap4
                                                        wrapperClasses="table-responsive"
                                                        //selectRow={ selectRow }
                                                        //defaultSorted={defaultSorted}
                                                        pagination={paginationFactory()}
                                                        defaultSorted={defaultSorted}
                                                        striped
                                                        hover
                                                        condensed
                                                        headerWrapperClasses="foo"
                                                        noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                    //filter={filterFactory()}
                                                    />
                                                    :
                                                    isLoading ?
                                                        <div style={{ justifyContent: 'center' }}>

                                                            <Row style={{ justifyContent: 'center' }}>
                                                                <Col style={{ justifyContent: 'center' }}>
                                                                    <Spinner type="grow" color="primary" />
                                                                    <Spinner type="grow" color="secondary" />
                                                                    <Spinner type="grow" color="success" />
                                                                    <Spinner type="grow" color="danger" />
                                                                    <Spinner type="grow" color="warning" />
                                                                    <Spinner type="grow" color="info" />
                                                                    <Spinner type="grow" color="light" />
                                                                    <Spinner type="grow" color="dark" />
                                                                </Col>
                                                            </Row> <br />
                                                        </div>
                                                        : null
                                            }

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (

                            <ToolkitProvider
                                keyField="id"
                                data={articles}
                                columns={columnsArticlesPhone}
                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={24} sm={24} className="ml-3" >

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ width: "auto", minWidth: "148%", maxWidth: "100%", display: 'flex' }}
                                                        //style={{ maxWidth: 450, width: 'auto', display: 'flex' }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>

                                                <Col xm={12} md={12} >
                                                    <FormGroup >
                                                        <RangePicker className="gx-w-100"
                                                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                            format={dateFormat}
                                                            onChange={this.onDateChange}
                                                            ranges={{
                                                                "Aujourd'hui": [moment(), moment()],
                                                                'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                                'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                                'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                            }}
                                                        />
                                                        <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                    </FormGroup>


                                                </Col>
                                                <Col className="mr-3"  xm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>
                                                    <FormGroup>
                                                        <Select

                                                            value={codAgenceChange}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: "auto", minWidth: "265%", maxWidth: "100%" }}
                                                            placeholder="Filtrer par Agence"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeAgence}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value=''>TOUTES</Option>
                                                            {
                                                                agences && agences.length ?
                                                                    agences.map((agence, k) => {
                                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                    }) : null
                                                            }

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                    </FormGroup>
                                                </Col>
                                                
                                            </Row>
                                            {articles &&
                                                Array.isArray(articles) &&
                                                articles.length > 0 &&
                                                <Row style={{display:"flex", justifyContent:"space-around", alignContent:"space-between"}}>
                                                    <Col md={24} sm={24} xm={24} lg={24} xl={24}>
                                                        <h3 style={{ color: "green", fontWeight: "bold" }}><u>Nombre Articles</u>: {`${numberWithCommas(totalArticle)}`}</h3>
                                                    </Col>
                                                    <Col md={24} sm={24} xm={24} lg={24} xl={24}>
                                                        <h3 style={{ color: "blue", fontWeight: "bold" }}><u>Total Quantié Vendue</u>: {`${numberWithCommas(totalQte)}`}</h3>
                                                    </Col>
                                                    <Col md={24} sm={24} xm={24} lg={24} xl={24}>
                                                        <h3 style={{ color:"red", fontWeight: "bold" }}><u>Montant</u>: { `${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                            }
                                            {articles &&
                                                Array.isArray(articles) &&
                                                articles.length > 0 ?
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                            }

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }


            </div>
        )
    }
}


export default ArticlesVendus;
