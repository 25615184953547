import React from "react";
import { Card, Form, Input, Divider, Icon, Button, Modal, Select, message, Popconfirm, InputNumber, DatePicker, Radio } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, ListGroup, ListGroupItem, Table, Spinner } from 'reactstrap';
import Media from 'react-media';

import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import 'moment/locale/fr-ca';
import locale from 'antd/es/date-picker/locale/fr_FR';

import { services } from '../../services/services';
import { authenticationService } from "../../services/authentication-service";

import { numberWithCommas } from '../../helpers/index';



const currentUser = authenticationService.currentUserValue

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const confirm = Modal.confirm;

const FormItem = Form.Item;

const moment = require('moment')

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker } = DatePicker;



class ChiffreAffaireClient extends React.Component {
    state = {

        formLayout: 'horizontal',

        codAgence: '',

        dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        startDate: moment(),
        endDate: moment(),

        clients: [],


        waitingEndMsg: '',
        isLoading: true,
        agences: [],
        isModalVisible: false,
        client: "",
        clientFacture: [],
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        const startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        //const dateEnCours= moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
        //console.log('Date', this.state.dateEnCours)
        services.getChiffreAffaireClient(startDate, endDate, '')
            .then(clients => {
               // console.log('CHIFFRE CLINET', clients)
                this.setState({ clients })

                setTimeout(() => {

                    // console.log("real clients", clients)
                    if (!clients || !Array.isArray(clients)
                        || !clients.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucune Facture pour cette période pour ce client!` })
                    }
                }, 500)
            })

        services.getAllAgences()
            .then(agences => {
                //  console.log('agences', agences)
                this.setState({ agences })
            })
    }

    handleChangeAgence = (value) => {

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.setState({ codAgenceChange: value })
        this.setState({ isLoading: true, waitingEndMsg: '', clients: [] })
        //  console.log("AGENCE CHANGE", this.state.dateEnCours,value, startDate, endDate)

        services.getChiffreAffaireClient(startDate, endDate, value)
            .then(clients => {
              //  console.log('ARTICLE VENDUS', clients)
                this.setState({ clients: clients, codAgence: value, isLoading: false, })

                setTimeout(() => {

                    // console.log("real clients", clients)
                    if (!clients || !Array.isArray(clients)
                        || !clients.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucun chiffre d'Affaire pour cette agence!` })
                    }
                }, 500)
            })

    }




    onDateChange = (date, dateString) => {
        // console.log("DATE", date, dateString, dateString[0]);
        this.setState({ isLoading: true, clients: [], waitingEndMsg: '' })

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //  console.log("DATE CHANGE PERIODE", dateString[0], dateString[1], this.state.dateEnCours, this.state.codAgence, startDate, endDate)

        services.getChiffreAffaireClient(startDate, endDate, this.state.codAgence)
            .then(clients => {
              //  console.log('ARTICLE VENDUS', clients)
                this.setState({ clients: clients, isLoading: false, })

                setTimeout(() => {

                    // console.log("real clients", clients)
                    if (!clients || !Array.isArray(clients)
                        || !clients.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucune Facture pour cette période pour ce client!` })
                    }
                }, 500)
            })
        this.setState({ startDate: dateString[0], endDate: dateString[1] })


    }


    handleCancel = () => {
        this.setState({
            isModalVisible: false,

            listeArticlesSelected: [],
        });
    }

    handleView = (client) => {

        this.setState({ client: client, clientFacture: client.factures, clientListeArticlesSelected: client.sousCommandes/*listGrouper.filter(x => x !== [])*/, listeArticlesSelected: client.listeArticlesSelected, isModalVisible: !this.state.isModalVisible })
    }



    render() {
        const {

            codAgenceChange,

            agences,


            clients,

            qteStock,

            formLayout,



            isLoading,
            waitingEndMsg,

            startDate,
            endDate,
            isModalVisible,
            listeArticlesSelected,
            clientListeArticlesSelected,
            client,
            clientFacture,

        } = this.state


        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;


        const columnsClients = [{
            dataField: 'codClient',
            text: 'Code',
            headerAttrs: { width: 50 },
        },
        {
            dataField: 'raisonSociale',
            text: 'Client'
        },
        {
            dataField: 'total',
            text: 'Montant Total',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            numberWithCommas(parseInt(row.total))
                        }
                    </div>
                );
            },
        },

        /*{
            dataField: 'qteTotal',
            text: 'Qtantité Vendue',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            numberWithCommas(parseInt(row.qteTotal))
                        }
                    </div>
                );
            },
        },


        {
            dataField: 'montTotal',
            text: 'Montant',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            numberWithCommas(parseInt(row.montTotal))
                        }
                    </div>
                );
            },
        },*/

        {
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                            <Button
                                title="Visualiser"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                        }


                    </div>
                );
            },

        }

        ];


        const columnsClientsPhone = [


            {
                dataField: 'raisonSociale',
                text: 'Client'
            },

            {
                dataField: 'total',
                text: 'Montant',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                numberWithCommas(parseInt(row.total))
                            }
                        </div>
                    );
                },
            },


            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }


                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'qteTotal',
            order: 'desc'
        }];

        let totalChiffre = clients && clients.length ?
            clients.reduce(function (prev, cur) {
                return prev + parseInt(cur.total)
            }, 0) : 0

        /*let totalQte = clients && clients.length ?
        clients.reduce(function (prev, cur) {
            return prev + parseInt(cur.qteTotal)
        }, 0) : 0*/

        let totalArticle = clients && clients.length

        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0

        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?
                    <Card title="Chiffre d'Affaire Client">

                        <Media query="(min-width: 600px)" render={() =>
                        (
                            <ToolkitProvider
                                keyField="id"
                                data={clients}
                                columns={columnsClients}
                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={3} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>

                                                <Col md={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                    <FormGroup className="mr-2">
                                                        <RangePicker className="gx-w-100"
                                                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                            format={dateFormat}
                                                            onChange={this.onDateChange}
                                                            ranges={{
                                                                "Aujourd'hui": [moment(), moment()],
                                                                'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                                'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                                'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                            }}
                                                        />
                                                        <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                    </FormGroup>


                                                    <FormGroup className="mr-4">
                                                        <Select

                                                            value={codAgenceChange}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: '120%' }}
                                                            placeholder="Filtrer par Agence"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeAgence}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value=''>TOUTES</Option>
                                                            {
                                                                agences && agences.length ?
                                                                    agences.map((agence, k) => {
                                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                    }) : null
                                                            }

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                    </FormGroup>

                                                </Col>


                                            </Row>
                                            {clients &&
                                                Array.isArray(clients) &&
                                                clients.length > 0 &&
                                                <Row >
                                                    <Col md={4} sm={4} xm={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>
                                                        <h3 style={{ color: "green", fontWeight: "bold" }}><u>Nombre clients</u>: {`${numberWithCommas(totalArticle)}`}</h3>
                                                    </Col>
                                                    {/*  <Col md={4} sm={4} xm={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-center', alignItems: 'baseline', }}>
                                                        <h3 style={{ color: "blue", fontWeight: "bold" }}><u>Total Quantié Vendue</u>: {`${numberWithCommas(totalQte)}`}</h3>
                                                     </Col>*/}
                                                    <Col md={4} sm={4} xm={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                        <h3 style={{ color: "red", fontWeight: "bold" }}><u>Montant</u>: {`${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                    </Col>
                                                </Row>
                                            }


                                            {
                                                !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                            }
                                            {
                                                clients &&
                                                    Array.isArray(clients) &&
                                                    clients.length > 0 ?
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        //bootstrap4
                                                        wrapperClasses="table-responsive"
                                                        //selectRow={ selectRow }
                                                        //defaultSorted={defaultSorted}
                                                        pagination={paginationFactory()}
                                                        defaultSorted={defaultSorted}
                                                        striped
                                                        hover
                                                        condensed
                                                        headerWrapperClasses="foo"
                                                        noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                    //filter={filterFactory()}
                                                    />
                                                    :
                                                    isLoading ?
                                                        <div style={{ justifyContent: 'center' }}>

                                                            <Row style={{ justifyContent: 'center' }}>
                                                                <Col style={{ justifyContent: 'center' }}>
                                                                    <Spinner type="grow" color="primary" />
                                                                    <Spinner type="grow" color="secondary" />
                                                                    <Spinner type="grow" color="success" />
                                                                    <Spinner type="grow" color="danger" />
                                                                    <Spinner type="grow" color="warning" />
                                                                    <Spinner type="grow" color="info" />
                                                                    <Spinner type="grow" color="light" />
                                                                    <Spinner type="grow" color="dark" />
                                                                </Col>
                                                            </Row> <br />
                                                        </div>
                                                        : null
                                            }

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (

                            <ToolkitProvider
                                keyField="id"
                                data={clients}
                                columns={columnsClientsPhone}
                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={24} sm={24} className="ml-3" >

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ width: "auto", minWidth: "148%", maxWidth: "100%", display: 'flex' }}
                                                        //style={{ maxWidth: 450, width: 'auto', display: 'flex' }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>

                                                <Col xm={12} md={12} >
                                                    <FormGroup >
                                                        <RangePicker className="gx-w-100"
                                                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                            format={dateFormat}
                                                            onChange={this.onDateChange}
                                                            ranges={{
                                                                "Aujourd'hui": [moment(), moment()],
                                                                'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                                'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                                'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                            }}
                                                        />
                                                        <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                    </FormGroup>


                                                </Col>
                                                <Col className="mr-3" xm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>
                                                    <FormGroup>
                                                        <Select

                                                            value={codAgenceChange}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: "auto", minWidth: "265%", maxWidth: "100%" }}
                                                            placeholder="Filtrer par Agence"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeAgence}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value=''>TOUTES</Option>
                                                            {
                                                                agences && agences.length ?
                                                                    agences.map((agence, k) => {
                                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                    }) : null
                                                            }

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            {clients &&
                                                Array.isArray(clients) &&
                                                clients.length > 0 &&
                                                <Row style={{ display: "flex", justifyContent: "space-around", alignContent: "space-between" }}>
                                                    <Col md={24} sm={24} xm={24} lg={24} xl={24}>
                                                        <h3 style={{ color: "green", fontWeight: "bold" }}><u>Nombre clients</u>: {`${numberWithCommas(totalArticle)}`}</h3>
                                                    </Col>
                                                    {/*<Col md={24} sm={24} xm={24} lg={24} xl={24}>
                                                        <h3 style={{ color: "blue", fontWeight: "bold" }}><u>Total Quantié Vendue</u>: {`${numberWithCommas(totalQte)}`}</h3>
                                                     </Col>*/}
                                                    <Col md={24} sm={24} xm={24} lg={24} xl={24}>
                                                        <h3 style={{ color: "red", fontWeight: "bold" }}><u>Montant</u>: {`${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                            }
                                            {clients &&
                                                Array.isArray(clients) &&
                                                clients.length > 0 ?
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                            }

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }


                <Modal
                    width={900}
                    visible={isModalVisible}
                    title="Détails Facture Client"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >


                    <Row>
                        <Col md={12}>
                            <h5 style={{ color: "#1D75BB" }} >Client:{client.raisonSociale || ''}  </h5>
                            <div className="text-left">
                                <p>Nombre Facture: {numberWithCommas(clientFacture.length || 0)} / Total: {numberWithCommas(parseInt(client.total))} F Cfa</p>
                                <p>Contact: {client.contact}</p>

                            </div>



                        </Col>
                    </Row>

                    <Media query="(max-width: 767px)" render={() =>
                    (

                        <Row>
                            <Col md={12}>

                                <div className="best-seller-table responsive-tbl">
                                    <div className="item">
                                        <div >
                                            <Table borderless /*style={{ maxHeight: '800', /*overflow: 'auto'/ }}*/ >
                                                <thead>
                                                    <tr>
                                                        <th >{"Désignation"}</th>
                                                        {/*<th>{"Prix Unitaire"}</th>*/}
                                                        <th>{"Quantité"}</th>
                                                        {/*<th>{"Net TTC"}</th>*/}
                                                    </tr>
                                                </thead>

                                                {
                                                    //SOUS BON COMMANDE
                                                    clientListeArticlesSelected && clientListeArticlesSelected.length ?
                                                        clientListeArticlesSelected.map((sousCommande, t) => {
                                                            return (
                                                                <tbody className="f-w-500">

                                                                    <h6 className="mt-3" style={{ color: "#1D75BB" }}>{`Sous Bon Commande N°: ${sousCommande.numSousCmde}`}</h6>

                                                                    {
                                                                        sousCommande.listeArticlesSelected && sousCommande.listeArticlesSelected.length ?
                                                                            sousCommande.listeArticlesSelected.map((article, k) => {
                                                                                return (
                                                                                    <tr key={k}>
                                                                                        <td>
                                                                                            <div className="media">

                                                                                                <div className="media-body"><span>{article.article && article.article.designation}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        {/* <td>
                              <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                           </td>*/}
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                        </td>
                                                                                        {/*<td>
                              <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F Cfa</span>
                            </td>*/}

                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                                                    }
                                                                </tbody>

                                                            );


                                                        }) : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                                }

                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                    )}
                    />

                    <Media query="(min-width: 768px)" render={() =>
                    (

                        <Row>
                            <Col md={12}>
                                <Card>


                                    <div className="best-seller-table responsive-tbl">
                                        <div className="item">
                                            <div className="table-responsive product-list">
                                                <Table borderless style={{ maxHeight: '800', overflow: 'auto' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="f-22">{"Désignation"}</th>
                                                            {/*<th>{"Prix Unitaire"}</th>*/}
                                                            <th>{"Quantité"}</th>
                                                            {/*<th>{"Net TTC"}</th>*/}
                                                        </tr>
                                                    </thead>


                                                    {
                                                        //SOUS BON COMMANDE
                                                        clientFacture && clientFacture.length ?
                                                            clientFacture.map((facture, t) => {
                                                                return (
                                                                    <tbody className="f-w-500">

                                                                        <h6 className="mt-3" style={{ color: "#1D75BB" }}>{`Facture N°: ${facture.numFact} | Date: ${moment(facture.dateFact, 'YYYY-MM-DD').format('DD/MM/YYYY')} | Montant: ${numberWithCommas(parseInt(facture.montFact))}`}</h6>

                                                                        {facture.listeArticlesSelected && facture.listeArticlesSelected.length ?
                                                                            facture.listeArticlesSelected.map((article, k) => {
                                                                                return (

                                                                                    <tr key={k}>
                                                                                        <td>
                                                                                            <div className="media">

                                                                                                <div className="media-body"><span>{article.article && article.article.designation}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        {/*<td>
                                      <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                                    </td>*/}
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                        </td>
                                                                                        {/*<td>
                                                                                            <span>{numberWithCommas(parseInt(article.netaPayer))/*+ (parseInt(article.puNetHt * article.tauxTva)) / 100)/} F Cfa</span>
                                                                                        </td>*/}

                                                                                    </tr>
                                                                                );
                                                                            })

                                                                            : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                                                        }
                                                                    </tbody>

                                                                );


                                                            }) : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                                    }







                                                </Table>

                                            </div>
                                        </div>
                                    </div>

                                </Card>
                            </Col>
                        </Row>

                    )}
                    />

                </Modal>


            </div>
        )
    }
}


export default ChiffreAffaireClient;
