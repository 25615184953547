import React from "react";
import { DatePicker, Card, Divider, Select, Input, Form, Checkbox } from "antd";

import { Table, Row, Col, FormGroup, } from 'reactstrap';
import Media from 'react-media';

//import { use_logiciel } from '../../env'

import { services } from '../../services/services'
import { numberWithCommas } from '../../helpers/index';

import moment from "moment";
import AmCharts from "@amcharts/amcharts3-react";
import { authenticationService } from "../../services/authentication-service";

const currentUser = authenticationService.currentUserValue
//console.log("currentUser", currentUser)

const { MonthPicker, RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
//const monthFormat = 'YYYY/MM';

//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;




class QuantiteVendues extends React.Component {
    state = {
        TotalQuantites: [],
        startDate: firstDay,
        endDate: lastDay,
        codAgence: '',
        formLayout: 'horizontal',
        agenceCheckedList: [],
        indeterminate: true,
        checkAll: true,
        defaultCkeckAgence: []


    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const startDate = moment(new Date(firstDay), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(lastDay), 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getAllAgences()
            .then(agences => {
              //  console.log('agences', agences)
                let defaultCkeckAgence = agences && agences.length ?
                    agences.map((details, k) => {
                        return details.codAgence
                    }) : []
                this.setState({ agences: agences, defaultCkeckAgence: defaultCkeckAgence })

                let req = {
                    startDate:startDate,
                    endDate: endDate,
                    codeAgence: defaultCkeckAgence,
        
                }
        
                services.getTotalQteVendu(req)
                    .then(TotalQuantites => {
                       // console.log('TotalQuantites', TotalQuantites)
                        this.setState({ TotalQuantites })
                    })
            })
    }



    //SELECTION AGENCE LORS CREATION OU MISE A JOUR USER
    handleChangeAgence = (value) => {
        this.setState({ codAgence: value })
    }


    //FILTRE POUR TABLEAU UTILISATEUR PAR AGENCE
    /*handleChangeAgence2 = (value) => {
        this.setState({ codAgenceChange: value })

        const startDate = moment(new Date(this.state.startDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(this.state.endDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        
        services.getTotalQteVendu(startDate, endDate, value)
            .then(TotalQuantites => {
                console.log('TotalQuantites2', TotalQuantites)
                this.setState({ TotalQuantites: TotalQuantites })
            })
    }*/

    onDateChange = (date, dateString) => {
        // console.log("DATE", date, dateString, dateString[0]);

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')

        let req = {
            startDate:startDate,
            endDate: endDate,
            codeAgence: this.state.defaultCkeckAgence,

        }

        services.getTotalQteVendu(req)
            .then(TotalQuantites => {
               // console.log('TotalQuantites2', TotalQuantites)
                this.setState({ TotalQuantites: TotalQuantites, startDate: dateString[0], endDate: dateString[1], })
            })
    }

    onCheckAgenceChange = (agence) => {
       // console.log("AGENCE CHEK", agence,)
        //this.setState({ defaultCkeckAgence: agence })
        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

        let req = {
            startDate:startDate,
            endDate: endDate,
            codeAgence: agence,

        }
        services.getTotalQteVendu(req)
            .then(TotalQuantites => {
             //   console.log('TotalQuantites2', req, TotalQuantites)
                this.setState({ TotalQuantites: TotalQuantites, defaultCkeckAgence: agence })
            })
    }


    render() {
        const {
            agences,

            codAgence,
            codAgenceChange,
            TotalQuantites,
            startDate,
            endDate,
            formLayout,
            agenceCheckedList,
            checkAll,
            defaultCkeckAgence,

        } = this.state

        /* const formItemLayout = formLayout === 'horizontal' ? {
             labelCol: { xs: 24, sm: 6 },
             wrapperCol: { xs: 24, sm: 14 },
         } : null;*/

        const Option = Select.Option;

        let data = TotalQuantites && TotalQuantites.length ?
            TotalQuantites.map((details, k) => {
                return {
                    "date": moment(new Date(details.date), 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    "total": details.total
                } //details && details.article ? details.article.codPro : ''
            }) : []
        //console.log("DETAILS", data)

        /* let sortedData = data.sort(
             (p1, p2) => (p1.chiffre < p2.chiffre) ? 1 : (p1.chiffre > p2.chiffre) ? -1 : 0);
         // console.log("sortedData", sortedData)*/

        let totalChiffre = data && data.length ?
            data.reduce(function (prev, cur) {
                return prev + parseInt(cur.total)
            }, 0) : 0
       // console.log("totalChiffre", totalChiffre)


       // console.log("defaultCkeckAgence", defaultCkeckAgence)

        const config = {
            "theme": "light",
            "type": "serial",
            "marginRight": 80,
            "autoMarginOffset": 20,
            "marginTop": 20,
            "dataDateFormat": "DD-MM-YYYY",
            "dataProvider": data,
            "valueAxes": [{
              "id": "v1",
              "axisAlpha": 0.1
            }],
            "graphs": [{
              "useNegativeColorIfDown": true,
              "balloonText": "[[category]]<br><b>Total Quantités: [[value]]</b>",
              "bullet": "round",
              "bulletBorderAlpha": 1,
              "bulletBorderColor": "#FFFFFF",
              "hideBulletsCount": 50,
              "lineThickness": 2,
              "lineColor": "#34C924",
              "negativeLineColor": "#F00020",
              "valueField": "total"
            }],
            "chartScrollbar": {
              "scrollbarHeight": 5,
              "backgroundAlpha": 0.1,
              "backgroundColor": "#868686",
              "selectedBackgroundColor": "#67b7dc",
              "selectedBackgroundAlpha": 1
            },
            "chartCursor": {
              "valueLineEnabled": true,
              "valueLineBalloonEnabled": true
            },
            "categoryField": "date",
            "categoryAxis": {
              "parseDates": true,
              "axisAlpha": 0,
              "minHorizontalGap": 60
            },
            "export": {
              "enabled": false
            }
          };
        
        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" && currentUser.agence.enCours === true) ?
                    <Card title="Total Quantités Vendues">

                        <Media query="(min-width: 600px)" render={() =>
                        (

                            <div>
                                <Row >
                                    <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                    </Col>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black", fontWeight: "bold" }}><span>Filtrer par Période</span></h6>
                                        </FormGroup>

                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <Card className="gx-card" title="Agences" >
                                            <div className="gx-border-bottom gx-pb-3 gx-mb-3">

                                                <Checkbox.Group value={defaultCkeckAgence}  /*defaultValue={defaultCkeckAgence}*/ style={{ width: '100%' }} onChange={this.onCheckAgenceChange}>

                                                    {
                                                        agences && agences.length ?
                                                            agences.map((agence, k) => {
                                                                return <div>
                                                                    <Checkbox
                                                                        value={agence.codAgence}

                                                                        className="gx-mb-3"
                                                                        //onChange={this.onCheckAgenceChange}
                                                                        defaultChecked
                                                                        //checked={checkAll}
                                                                        //disabled={true}
                                                                        key={k}
                                                                        id={k}
                                                                    >
                                                                        {agence.nomAgence}
                                                                    </Checkbox><br />
                                                                </div>
                                                            }) : null
                                                    }

                                                </Checkbox.Group>


                                            </div>
                                            <h3 style={{ color: "red" }}>Total Quantités: {(totalChiffre === 0)? 0 :`${numberWithCommas(totalChiffre)}`}</h3>

                                            {/*<CheckboxGroup options={plainOptions} value={this.state.agenceCheckedList} onChange={this.onChange} />*/}
                                        </Card>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>

                                </Row>



                            </div>


                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (


                            <div>
                                <Row >
                                    <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                    </Col>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                        <FormGroup className="">
                                            <RangePicker className="gx-w-100"
                                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                format={dateFormat}
                                                onChange={this.onDateChange}
                                                ranges={{
                                                    "Aujourd'hui": [moment(), moment()],
                                                    'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                    'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                    'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                }}
                                            />
                                            <h6 className="mr-3" style={{ color: "black" , fontWeight: "bold"}}><span>Filtrer par Période</span></h6>
                                        </FormGroup>

                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <Card className="gx-card" title="Agences" >
                                            <div className="gx-border-bottom gx-pb-3 gx-mb-3">

                                                <Checkbox.Group value={defaultCkeckAgence}  /*defaultValue={defaultCkeckAgence}*/ style={{ width: '100%' }} onChange={this.onCheckAgenceChange}>

                                                    {
                                                        agences && agences.length ?
                                                            agences.map((agence, k) => {
                                                                return <div>
                                                                    <Checkbox
                                                                        value={agence.codAgence}

                                                                        className="gx-mb-3"
                                                                        //onChange={this.onCheckAgenceChange}
                                                                        defaultChecked
                                                                        //checked={checkAll}
                                                                        //disabled={true}
                                                                        key={k}
                                                                        id={k}
                                                                    >
                                                                        {agence.nomAgence}
                                                                    </Checkbox><br />
                                                                </div>
                                                            }) : null
                                                    }

                                                </Checkbox.Group>


                                            </div>
                                            <h3 style={{ color: "red" }}>Total Quantités: {(totalChiffre === 0)? 0 :`${numberWithCommas(totalChiffre)}`}</h3>

                                            {/*<CheckboxGroup options={plainOptions} value={this.state.agenceCheckedList} onChange={this.onChange} />*/}
                                        </Card>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <Card className="gx-card" title="">
                                            <div className="App">
                                                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
                                            </div>
                                        </Card>
                                    </Col>

                                </Row>



                            </div>


                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }



            </div>
        )
    }
}


export default QuantiteVendues;
